import React, { useState, useEffect } from 'react';
import './styles.scss';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateValue } from '../../../../state';
import {
  Form,
  Col,
  Button,
  Alert,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Modal,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUndo, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {
  INDUSTRIES,
  DURATIONS,
  TYPES,
  DISTANCES,
  CATERER_INDUSTRIES
 } from '../../../../constants/searchbox.js';

const SearchBox = ({handleSubmit, resetSearch, formError}) => {
  const [{ boardCfgs, user2, savedSearches }, dispatch] = useStateValue();
  const [keywords, setKeywords] = useState('');
  const [town, setTown] = useState('');
  const [distance, setDistance] = useState(25);
  const [date, setDate] = useState(14);
  const [industry, setIndustry] = useState('');
  const [type, setType] = useState('');
  const [show, setShow] = useState(false);
  const [savedSearchName, setSavedSearchName] = useState();
  const [loadingSave, setLoadingSave] = useState(false);
  const [catererIndustry, setCatererIndustry] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  }

  const state = {
    keywords,
    town,
    distance,
    date,
    industry,
    type,
    catererIndustry
  }

  let saveBtnState = true;

  if(keywords.length && town.length) {
    saveBtnState = false;
  }

  const clearForm = (e) => {
    e.preventDefault();
    setKeywords('');
    setTown('');
    setDistance(25);
    setDate(14);
    setIndustry('');
    setType('');
    resetSearch();
  }

  const showCatererInd = boardCfgs.find(x => 4 === parseInt(x.boardId) & parseInt(x.active) === 1);

  const testReed = async (email, sessionID, triggered) => {
    const result = await axios({
      url: `${process.env.REACT_APP_API}/reed`,
      method: 'get',
    });

    // result.data = JSON.parse(result.data);
  }

  const loadSearch = (e, search) => {
    setLoadingSave(true);
    const s = JSON.parse(search.data);
    setKeywords(s.keywords);
    setTown(s.town);
    setDistance(s.distance);
    setDate(s.date);
    setIndustry(s.industry);
    setType(s.type);
    setCatererIndustry(s.catererIndustry);
  }

  useEffect(() => {
    if (loadingSave && parseInt(user2.autoSearchOnClick) === 1) {
      setLoadingSave(false);
      handleSubmit(false, state, false, true);
    }

  }, [loadingSave])

  const saveSearch = async () => {
    const data = {}
    data.keywords = keywords;
    data.industry = industry;
    data.type = type;
    data.town = town;
    data.distance = distance;
    data.date = date;
    data.catererIndustry = catererIndustry;

    const result = await axios({
      url: `${process.env.REACT_APP_API}/saveSearch`,
      method: 'post',
      data: {
        name: savedSearchName,
        u: user2.userId,
        s: user2.sessionID,
        data: JSON.stringify(data)
      }
    });

    console.log(JSON.stringify(data));


    result.data = JSON.parse(result.data);

    savedSearches.unshift(result.data);

    dispatch({
      type: 'SET_SAVED_SEARCHES',
      data: savedSearches
    })

    setSavedSearchName('');
    handleClose();
    toast("Save search added");
  }

  useEffect(() => {
    const fetchSavedSearches = async () => {
      const result = await axios({
        url: `${process.env.REACT_APP_API}/fetchSavedSearches`,
        method: 'post',
        data: {
          u: user2.userId,
          s: user2.sessionID
        }
      });
      result.data = JSON.parse(result.data);
      dispatch({
        type: 'SET_SAVED_SEARCHES',
        data: result.data,
      })
    }

    fetchSavedSearches();
  }, []);

  // ADD THIS TO TEST REED LATER
  // <Button variant="primary" className="searchBtn" type="submit" onClick={(e) => testReed(e, state, false)}>
  //  <FontAwesomeIcon icon={faSearch} className="iconLeft" color="white" size="1x" /> &nbsp;
  //  <label className="searchText">TEST REED</label>
  // </Button>

  return <div className="searchFormContainer">
            <div className="searchForm">
              <ToastContainer />
              <Form>
                <Form.Row>
                  <Form.Group as={Col} md="5" controlId="formGridAddress1">
                    <Form.Label>Keywords / Job title</Form.Label>
                    {
                      formError && !keywords && <div className="fieldError"><FontAwesomeIcon icon={faExclamationTriangle} color="#c0392b" size="1x" /></div>
                    }
                    <Form.Control placeholder="e.g. Graphic Designer" className="keywords" type="text" value={keywords} onChange={(e) => { setKeywords(e.target.value) }} />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Industry</Form.Label>
                      <div className="selectdiv">
                      <Form.Control as="select" value={industry} className="select" onChange={(e) => { setIndustry(e.target.value) }} >
                        <option value="">All</option>
                        {
                          INDUSTRIES.map(industry => {
                            return <option key={industry.cvL} value={industry.cvL}>{industry.cvL}</option>
                          })
                        }
                      </Form.Control>
                    </div>
                  </Form.Group>

                  <Form.Group as={Col} md="3">
                    <Form.Label>Job Type</Form.Label>
                      <div className="selectdiv">
                        <Form.Control as="select" value={type} className="select" onChange={(e) => { setType(e.target.value) }} >
                          {
                            TYPES.map(row => {
                              return <option key={row.value} value={row.value}>{row.name}</option>
                            })
                          }
                        </Form.Control>
                      </div>
                  </Form.Group>

                </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="5">
                      <Form.Label>Full UK Postcode</Form.Label>
                        {
                          formError && !town && <div className="fieldError"><FontAwesomeIcon icon={faExclamationTriangle} color="#c0392b" size="1x" /></div>
                        }
                      <Form.Control type="text" placeholder="e.g. W1T 4JZ" value={town} onChange={(e) => { setTown(e.target.value) }}/>
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                      <Form.Label>Distance (miles)</Form.Label>
                        <div className="selectdiv">
                          <Form.Control as="select" value={distance} className="select" onChange={(e) => { setDistance(e.target.value) }} >
                            {
                              DISTANCES.map(distance => {
                                return <option key={distance} value={distance}>{distance}</option>
                              })
                            }
                          </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                      <Form.Label>Searching in the last</Form.Label>
                        <div className="selectdiv">
                          <Form.Control as="select" value={date} className="select" onChange={(e) => { setDate(e.target.value) }}>
                              {
                                DURATIONS.map(row => {
                                  return <option key={row.value} value={row.value}>{row.name}</option>
                                })
                              }
                          </Form.Control>
                        </div>
                    </Form.Group>
                  </Form.Row>
                  {
                    showCatererInd &&
                      <Form.Row>
                        <Form.Group as={Col} md="3">
                          <Form.Label>Caterer Industry</Form.Label>
                            <div className="selectdiv">
                              <Form.Control as="select" value={catererIndustry} className="select" onChange={(e) => { setCatererIndustry(e.target.value) }}>
                                <option value="">All</option>
                                {
                                  CATERER_INDUSTRIES.map(ind => {
                                    return <option key={ind} value={ind}>{ind}</option>
                                  })
                                }
                              </Form.Control>
                            </div>
                        </Form.Group>
                      </Form.Row>
                    }
                  {
                    formError && <Alert variant="danger">
                                    Please provide a keyword and a location
                                  </Alert>
                  }
                <Button variant="primary" className="searchBtn" type="submit" onClick={(e) => handleSubmit(e, state, false, true)}>
                 <FontAwesomeIcon icon={faSearch} className="iconLeft" color="white" size="1x" /> &nbsp;
                 <label className="searchText">Search</label>
                </Button>

                <Button variant="primary" className="resetBtn" type="submit" onClick={clearForm}>
                   <FontAwesomeIcon icon={faUndo} className="iconLeft" color="white" size="1x" /> &nbsp;
                   <label className="searchText">Reset</label>
                </Button>

                <Button variant="primary" disabled={saveBtnState} className="searchBtn saveBtn" type="submit" onClick={(e) => handleShow(e, state)}>
                 <FontAwesomeIcon icon={['far', 'save']} size="1x" /> &nbsp;
                 <label className="searchText">Save Search</label>
                </Button>

                <DropdownButton as={ButtonGroup} title="Load Search" id="bg-vertical-dropdown-3">
                  {
                    savedSearches && savedSearches.length ? savedSearches.map((search) => {
                      return <Dropdown.Item key={search.savedSearchId} eventKey={search.savedSearchId} onClick={(e) => loadSearch(e, search)}>{search.name}</Dropdown.Item>
                    })
                    :
                      <Dropdown.Item eventKey="999" disabled={true}>You have not created any saved searches yet</Dropdown.Item>
                  }
                </DropdownButton>
              </Form>
            </div>

            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Save search</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <p>You can save this search and load it in the future. Please provide the name of this save search:</p>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="e.g Web Developers in Manchester"
                  aria-label="e.g Web Developers in Manchester"
                  aria-describedby="basic-addon1"
                  value={savedSearchName}
                  onChange={(e) => setSavedSearchName(e.target.value)}
                />
                </InputGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" disabled={!savedSearchName ? true : false} onClick={() => saveSearch()}>
                <FontAwesomeIcon icon={['far', 'save']} size="1x" /> &nbsp;
                  Save
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  <FontAwesomeIcon icon={['far', 'times-circle']} size="1x" /> &nbsp;
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
        </div>
}

export default SearchBox;
