import React, { useState, useEffect } from 'react';
import { Table, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import AddClient from './AddClient';
import DeleteClient from './DeleteClient';
import EditClient from './EditClient';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import {
  faTimesCircle,
  faTimes
 } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import './styles.scss';

const SettingsUsers = ({user}) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [modalType, setModalType] = useState();
  const [clients, setClients] = useState(false);

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      minHeight            : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  const openModal = (type, client) => {
    setModalIsOpen(true);
    setModalType(type);
    setSelectedClient(client);
  }

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const refresh = () => {
    closeModal();
    fetchClients();
  }

  const fetchClients = async () => {
    const result = await axios({
      url: `${process.env.REACT_APP_API}/fetchClients`,
      method: 'post',
      data: {
        user: user.email,
        sid: user.sessionID
      }
    });
    result.data = JSON.parse(result.data);
    setClients(result.data);
  }

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <div className="clientSettings">
      <h1>Clients</h1>
      <ToastContainer />
        <Button className="addButton" type="submit" onClick={() => { openModal('addClient') }}>
         <FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" /> &nbsp;
         <label className="searchText">Add client</label>
        </Button>
        <div className="scrollbar scrollbar3" id="style-5">
          <div className="force-overflow">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Client Name</th>
                  <th>Users</th>
                  <th className="textAlignCenter">Edit</th>
                  <th className="textAlignCenter">Delete</th>
                </tr>
              </thead>
              <tbody>
                {
                  clients && clients.length ?
                    clients.map((client) => {
                      return <tr key={client.clientName}>
                                <td>{client.myid}</td>
                                <td>{client.clientName}</td>
                                <td>{client.numUsers ? client.numUsers : 0}/{client.userLimit}</td>
                                <td className="textAlignCenter">
                                  <Button variant="primary" className="activateBoard fixedDimButton noMarginRight" type="submit" onClick={() => { openModal('editClient', client)}}>
                                   <FontAwesomeIcon icon={['fal', 'file-edit']} size="lg" /> &nbsp;
                                     Edit
                                  </Button>
                                </td>
                                <td className="textAlignCenter">
                                    <Button variant="primary" className="disabledBoard fixedDimButton noMarginRight" type="submit" onClick={() => { openModal('delete', client) }}>
                                     <FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" /> &nbsp;
                                       Delete
                                    </Button>
                                </td>
                              </tr>
                    })
                    :
                    <tr>
                      <td colSpan="6">
                        <div className="spinnerContainer">
                          <Loader
                             type="ThreeDots"
                             color="#02547d"
                             height={70}
                             width={70}
                             timeout={12000} //3 secs
                          />
                        </div>
                      </td>
                    </tr>
                }
              </tbody>
            </Table>
          </div>
        </div>

        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Candidate">
          <button className="closeModal" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="black" size="1x" /></button>
            {
              modalType === 'delete' &&
                <DeleteClient closeModal={closeModal} refresh={refresh} user={user} selectedClient={selectedClient} />
            }
            {
              modalType === 'addClient' &&
                <AddClient closeModal={closeModal} refresh={refresh} user={user} />
            }
            {
              modalType === 'editClient' &&
                <EditClient closeModal={closeModal} refresh={refresh} user={user} selectedClient={selectedClient}/>
            }
        </Modal>
    </div>
  )
}

export default SettingsUsers
