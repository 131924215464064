import React, { useState } from 'react';
import { Form, Button, Col, Table, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import ChangePassword from './ChangePassword';
import DeleteSearch from './DeleteSearch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateValue } from '../../../../state';
import axios from 'axios';
import './styles.scss';

const SettingsAccount = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [currentSearch, setCurrentSearch] = useState();
  const [{ user2, savedSearches }, dispatch] = useStateValue();
  const [title, setTitle] = useState();
  const [body, setBody] = useState();

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      minHeight            : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  const openModal = (e, data) => {
    e.preventDefault();
    setModalIsOpen(true);
    data && setCurrentSearch(data);
    data ? setModalType('deleteSearch') : setModalType('changePassword');
  }

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  const closeModal = (msg) => {
    setModalIsOpen(false);
    msg && toast(msg);
  }

  const toggleAutoSearch = async () => {
    await axios({
      url: `${process.env.REACT_APP_API}/toggleAutoSearch`,
      method: 'post',
      data: {
        currentValue: user2.autoSearchOnClick,
        user: user2.userId,
        sid: user2.sessionID
      }
    });

    const newUser2 = user2;
    newUser2.autoSearchOnClick = parseInt(newUser2.autoSearchOnClick) === 1 ? 0 : 1;

    dispatch({
      type: 'TOGGLE_AUTOSEARCH_VALUE',
      user: newUser2
    })
  }

  const updateOverlay = (e, search) => {
    const data = JSON.parse(search.data);
    const overlayBody = <div className="overlayBody">
                          <div class="overlayRow"><label>Keywords:</label> {data.keywords}</div>
                          <div class="overlayRow"><label>Town:</label> {data.town}</div>
                          <div class="overlayRow"><label>Industry:</label> {data.industry}</div>
                          <div class="overlayRow"><label>Distance:</label> {data.distance}</div>
                          <div class="overlayRow"><label>Industry:</label> {data.industry}</div>
                          <div class="overlayRow"><label>Age:</label> {data.date} days</div>
                        </div>
    setTitle(search.name);
    setBody(overlayBody);
  }

  const popover = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">{title}</Popover.Title>
    <Popover.Content>
      {body}
    </Popover.Content>
  </Popover>
);

  return (
    <>
      <h1>Account</h1>
      <ToastContainer />
        <h5>LOGIN CREDENTIALS</h5>
        <Form>
          <Form.Row>
          <Form.Group as={Col} controlId="formBasicEmail" md="7">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" value={user2.email} disabled={true} placeholder="Enter email" />
          </Form.Group>
        </Form.Row>

          <Form.Row>
          <Form.Group as={Col} controlId="formBasicPassword" md="7">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" disabled placeholder="********" />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="changePassword" md="2">
            <Button variant="primary" className="activateBoard changePwButton" type="submit" onClick={(e) => openModal(e)}>
              <FontAwesomeIcon icon={['fas', 'unlock-alt']} size="lg" /> &nbsp;
              <label className="searchText changePassword">Change Password</label>
            </Button>
          </Form.Group>
        </Form.Row>
        </Form>

        <h5>SAVED SEARCHES</h5>
        <div className="radioText">
          <Form.Check
            type="checkbox"
            id="auto-search"
            checked={parseInt(user2.autoSearchOnClick) === 1 && true }
            onClick={() => toggleAutoSearch()}
            label="Automatically execute searches on load search selection"
          />
        </div>
        <div className="form-row">
          <div className="col-md-7 savedSearchContainer">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="textAlignCenter">Delete</th>
                </tr>
              </thead>
              <tbody>
                {
                  savedSearches && savedSearches.length ?
                    savedSearches.map((search) => {
                      return <tr key={search.savedSearchId}>
                                <td>
                                  <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose={true}>
                                    <span className="savedSearchItem" onClick={(e) => updateOverlay(e, search)}>{search.name}</span>
                                  </OverlayTrigger>
                                </td>
                                <td className="textAlignCenter">
                                  <Button variant="primary" className="disabledBoard fixedDimButton" type="submit" onClick={(e) => { openModal(e, search) }}>
                                   <FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" /> &nbsp;
                                     Delete
                                  </Button>
                                </td>
                              </tr>
                    })
                    :
                    <tr>
                      <td>You have no saved searches</td>
                    </tr>
                }
              </tbody>
            </Table>
          </div>
        </div>

        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Candidate">
          <button className="closeModal" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="black" size="1x" /></button>
          {
            modalType === 'deleteSearch' ? <DeleteSearch currentSearch={currentSearch} closeModal={closeModal} /> : <ChangePassword closeModal={closeModal} />
          }

      </Modal>
  </>
)}

export default SettingsAccount
