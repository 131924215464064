import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditBoard from './EditBoard';
import axios from 'axios';
import cvLibrary from '../../../../images/cv-library.png';
import reed from '../../../../images/reed.png';
import total from '../../../../images/total.png';
import caterer from '../../../../images/caterer.png';
import cw from '../../../../images/cw.png';
import {
  faCheckCircle,
  faTimesCircle,
  faTimes,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import './styles.scss';
import { useStateValue } from '../../../../state';

const SettingsJobBoards = ({updateCredentials}) => {
  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      minHeight            : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  const [{ boardCfgs }, dispatch] = useStateValue();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [selectedBoard, setSelectedBoard] = useState();

  const openModal = (type, board) => {
    setModalIsOpen(true);
    setModalType(type);
    setSelectedBoard(board);
    // setCurrentCandidate(name);
  }

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const toggleActive = async () => {
    const updatedConfigs = [];

    boardCfgs.map((board) => {
      if(board.boardId === selectedBoard.boardId) {
        board.active = parseInt(board.active) === 0 ? 1 : 0;
      }
      updatedConfigs.push(board);
    })

    await axios({
      url: `${process.env.REACT_APP_API}/toggleBoard`,
      method: 'post',
      data: {
        id: selectedBoard.configId,
        toggle: selectedBoard.active
      }
    });

    dispatch({
      type: 'UPDATE_BOARD_CONFIGS',
      data: updatedConfigs,
    })

    closeModal();
  }

  const confirmSave = (msg) => {
    toast(msg);
  }

  return (
  <div className="jobBoard">
    <ToastContainer />
    <h1>Job Boards</h1>
      <div className="scrollbar scrollbar3" id="style-5">
        <div className="force-overflow">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="textAlignCenter">Board</th>
                <th className="textAlignCenter">Name</th>
                <th className="textAlignCenter">Status</th>
                <th className="textAlignCenter">Toggle</th>
                <th className="textAlignCenter">Edit</th>
              </tr>
            </thead>
            <tbody>
              {
                boardCfgs.map((board) => {
                  let img;
                  switch(parseInt(board.boardId)) {
                    case 1:
                     img = cvLibrary;
                    break;
                    case 2:
                      img = reed;
                    break;
                    case 3:
                      img = total;
                    break;
                    case 4:
                      img = caterer;
                    break;
                    case 5:
                      img = cw;
                    break;
                    default:
                      img = cvLibrary;
                  }
                  return (
                      <tr key={board.boardName}>
                        <td className="textAlignCenter"><img alt={board.boardName} src={img} width="80"/></td>
                        <td className="textAlignCenter">{board.boardName}</td>
                        <td className="textAlignCenter">{ board.active ? <span>{board.active && (board.active === true  || parseInt(board.active) === 1) ? 'Enabled' : <span className="boardDisabled">Disabled</span>}</span> : <span className="boardDisabled">Disabled</span> }</td>
                        <td className="textAlignCenter">
                          {
                            board.active && (board.active === true  || parseInt(board.active) === 1) ?
                            <Button variant="primary" className="disabledBoard fixedDimButton noMarginRight" type="submit" onClick={() => openModal('disable', board)}>
                             <FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" /> &nbsp;
                               Disable
                            </Button>
                            :
                            <Button variant="primary" className="activateBoard fixedDimButton noMarginRight" disabled={false} type="submit" onClick={() => openModal('enable', board)}>
                             <FontAwesomeIcon icon={faCheckCircle} color="white" size="1x" /> &nbsp;
                               Enable
                            </Button>
                          }
                        </td>
                        <td className="textAlignCenter">
                          <Button variant="primary" className="editBoard fixedDimButton noMarginRight" type="submit" onClick={() => openModal('edit', board)}>
                           <FontAwesomeIcon icon={['fal', 'file-edit']} size="lg" /> &nbsp;
                           Edit
                          </Button>
                        </td>
                      </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </div>

      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Candidate">
        <button className="closeModal" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="black" size="1x" /></button>
        {
          modalType === 'disable' &&
            <>
              <h1>Disable {selectedBoard && selectedBoard.boardName}</h1>
              <p>Are you sure you want to disable this job board? It will will no longer be available on your search results.</p>
              <div className="modalFooter">
                <Button variant="success" className="activateBoard" onClick={() => { toggleActive(selectedBoard) }}><FontAwesomeIcon icon={faCheck} color="white" size="1x" />&nbsp; Confirm</Button>
                <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
              </div>
            </>

        }
        {
          modalType === 'enable' &&
            <>
              <h1>Enable {selectedBoard && selectedBoard.boardName}</h1>
              <p>Enable this job board?</p>
              <div className="modalFooter">
                <Button variant="success" className="activateBoard" onClick={() => { toggleActive() }}><FontAwesomeIcon icon={faCheck} color="white" size="1x" />&nbsp; Confirm</Button>
                <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
              </div>
            </>
        }
        {
          modalType === 'edit' &&
            <EditBoard selectedBoard={selectedBoard} closeModal={closeModal} updateCredentials={updateCredentials} confirmSave={confirmSave} />
        }
      </Modal>
    </div>

  )
}

export default SettingsJobBoards
