import moment from 'moment';


export const rebuildSlObj = (profile) => {

  if(!profile.boardId) {
    return profile;
  }

  if (profile.boardId === 2) {
    const rebuild = {
      name: profile.name,
      town: profile.town ? profile.town : 'Not provided',
      boardId: profile.boardId,
      lastModified: moment(profile.lastModified).format('YYYY-MM-DD h:mm:ss a'),
      mobile: profile.MobilePhone,
      phone: profile.LandlinePhone,
      email: profile.Email
    }

    return rebuild
  }

  const rebuild = {
    name: `${profile.ForeName} ${profile.Surname}`,
    town: profile.CurrentLocation ? profile.CurrentLocation : 'Not provided',
    boardId: profile.boardId,
    lastModified: profile.LastUpdatedDate,
    mobile: profile.MobilePhone,
    phone: profile.LandlinePhone,
    email: profile.Email
  }

  return rebuild;
}

export default rebuildSlObj;
