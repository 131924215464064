import React, { useState } from 'react';
import NavLinks from '../NavLinks';
import Sidebar from '../Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import { useStateValue } from '../../../state';
import SettingsJobBoards from './SettingsJobBoards';
import SettingsAccount from './SettingsAccount';
import SettingsUsers from './SettingsUsers';
import SettingsClients from './SettingsClients';
import './styles.scss';

const Settings = ({logout, updateCredentials}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [{ user2 }] = useStateValue();
  const toggleTab = (tab) => {
    setActiveTab(tab);
  }

  return <div className="appContainer">
          <div className="gradient">
            <Sidebar user={user2} />
            <NavLinks user={user2} logout={logout}/>
              <div className="tabsWrapper">
                <div className="tabsContainer verticalTabsContainer">
                  <div className="sideTab">
                    <ul className="sideTabs">
                      <li className={activeTab===1 ? "active" : '' } onClick={() => { toggleTab(1) }}><FontAwesomeIcon icon={['fal', 'globe']} size="lg" />&nbsp; &nbsp;Boards</li>
                      {
                        user2.access > 2 && <li className={activeTab===2 ? "active" : '' } onClick={() => { toggleTab(2) }}><FontAwesomeIcon icon={['fas', 'user-tie']} size="lg" />&nbsp; &nbsp;Clients</li>
                      }
                      {
                        user2.access > 1 && <li className={activeTab===3 ? "active" : '' } onClick={() => { toggleTab(3) }}><FontAwesomeIcon icon={['fas', 'users']} size="lg" />&nbsp; &nbsp;Users</li>
                      }
                      <li className={activeTab===4 ? "active" : '' } onClick={() => { toggleTab(4) }}><FontAwesomeIcon icon={faUserCog} color="#434247" size="1x" />&nbsp; &nbsp;Account</li>

                    </ul>
                  </div>
                  <div className="verticalTabsContent">
                    {
                      activeTab === 1 &&
                        <SettingsJobBoards user={user2} updateCredentials={updateCredentials} />
                    }
                    {
                      activeTab === 2 &&
                        <SettingsClients user={user2} />
                    }
                    {
                      activeTab === 3 &&
                        <SettingsUsers user={user2} />
                    }
                    {
                      activeTab === 4 &&
                        <SettingsAccount user={user2} />
                    }

                  </div>
                </div>
              </div>
          </div>
        </div>
}

export default Settings;
