import React from 'react';
import './styles.scss';
import logo from '../../../images/logo.svg';
import JobBoards from './JobBoard';
import cvLibrary from '../../../images/cv-library.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Sidebar = () => {
  return <div className="sidebar">
            <img src={logo} width="125" alt="Vacancy Poster "/>
            <hr />
            <div className="jobBoards">
              <h2><FontAwesomeIcon icon={['fal', 'globe']} size="lg" /> Providers</h2>
              <JobBoards image={cvLibrary} />
            </div>
        </div>
}

export default Sidebar;
