import React, { useState } from 'react';
import './App.scss';
import './bootstrap.min.css';
import Login from './components/Login';
import { Switch, Route, Redirect } from 'react-router-dom';
import { StateProvider } from './state';
import MainApp from './components/MainApp';
import Settings from './components/MainApp/Settings';
import Shortlists from './components/MainApp/Shortlists';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';

library.add(fal);
library.add(fas);
library.add(far);

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState();

  const initialState = {
    boardCfgs: '',
    user2: '',
    cvLCred: '',
    loadingCaterer: true,
    activeBoardTab: 1,
    cvLibraryOffset: 0,
    totalTotalRecords: 0,
    catererLoading: false,
    cvLibraryLoading: false,
    reedLoading: false,
    reedFull: false,
    totalOffset: 0,
    allTabData: []
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'AUTHENTICATE_USER_REQUEST':
        setLoggedIn(true);
        return {
          ...state,
          user2: action.data,
          boardCfgs: action.boardCfgs
        };

        case 'UPDATE_BOARD_CONFIGS':
          return {
            ...state,
            boardCfgs: action.data
          };
        case 'SET_SAVED_SEARCHES':
          return {
            ...state,
            savedSearches: action.data
          };
        case 'CFGUPDATE':
          return {
            ...state,
            value: action.value
          };
        case 'TOGGLE_AUTOSEARCH_VALUE':
          return {
            ...state,
            user2: action.user
          };
        case 'UPDATE_ACTIVE_BOARD_TAB':
          return {
            ...state,
            activeBoardTab: action.tab
          }
        case 'CATERER_LOADING':
          return {
            ...state,
            catererLoading: action.value
          };
        case 'CVL_LOADING':
          return {
            ...state,
            cvLibraryLoading: action.value
          };
        case 'REED_LOADING':
          return {
            ...state,
            reedLoading: action.value
          };
        case 'TOTAL_LOADING':
          return {
            ...state,
            totalLoading: action.value
          };
        case 'CW_LOADING':
          return {
            ...state,
            cwLoading: action.value
          };
        case 'ALL_LOADING':
          return {
            ...state,
            allLoading: action.value
          };
        case 'SET_ACTIVE_TAB':
          return {
            ...state,
            activeBoardTab: action.value
          };
        case 'CATERER_FULL_DATA':
          return {
            ...state,
            catererFull: action.value
          }
        case 'CVL_FULL_DATA':
          return {
            ...state,
            cvlFull: action.value
          }
        case 'REED_FULL_DATA':
          return {
            ...state,
            reedFull: action.value
          }
        case 'TOTAL_FULL_DATA':
          return {
            ...state,
            totalFull: action.value
          }
        case 'CW_FULL_DATA':
          return {
            ...state,
            cwFull: action.value
          }
        case 'ALL_TAB_DATA':
          return {
            ...state,
            allTabData: action.value
          }
      default:
        return state;
    }
  };

  const logout = () => {
    setLoggedIn(false);
    localStorage.clear();
  }

  const retrieveSession = async () => {
    const result = await axios({
      url: `${process.env.REACT_APP_API}/fetchSession`,
      method: 'post',
      data: {
        vpsid: localStorage.getItem('vpSessionId')
      }
    });

    result.data = JSON.parse(result.data);

    setUserData(result.data);
    setLoggedIn(true);
  }

  !userData && localStorage.getItem('loggedIn') && retrieveSession();

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <div className="App" id="app">
        {
          loggedIn === true ?
          <>
          <Switch>
            <Route path='/dashboard' render={(props) => (
              <MainApp
                logout={logout} />)} />
            <Route path='/settings'
              render={(props) => (
                <Settings
                  logout={logout} />)} />
            <Route path='/shortlists'
              render={(props) => (
                <Shortlists
                  logout={logout} />)} />
            <Redirect
              to="/dashboard"
              component={ MainApp }
              logout={logout} />
            <Route render={ () => <h1>404 Error</h1> } />
          </Switch>
          </>
          :
          <>
            <Route exact path="/login" render={(props) =>
              <Login /> } />
        <Redirect to="/login"
              component={ MainApp } />
          </>
        }
      </div>
    </StateProvider>
  );
}

export default App;
