const INDUSTRIES = [
  {
    cvL: 'Accountancy',
    total: 'Accountancy',
    reed: 2,
  },
  {
    cvL: 'Administration',
    total: 'Secretarial, PAs, Administration',
    reed: 3,
  },
  {
    cvL: 'Advertising',
    total: 'Marketing, Advertising, PR',
    reed: 1875,
  },
  {
    cvL: 'Aerospace/Aviation/Defence',
    total: 'Aerospace',
    reed: 198,
  },
  {
    cvL: 'Agriculture',
    total: 'Agriculture, Fishing, Forestry',
    reed: 809,
  },
  {
    cvL: 'Architecture',
    total: 'Property',
    reed: 803,
  },
  {
    cvL: 'Art/Design/Graphics',
    total: 'Media, New Media, Creative',
    reed: 944,
  },
  {
    cvL: 'Automotive',
    total: 'Transport, Logistics',
    reed: 1700,
  },
  {
    cvL: 'Banking',
    total: 'Banking, Insurance, Finance',
    reed: 5,
  },
  {
    cvL: 'Biotechnology',
    total: 'Science',
    reed: 270,
  },
  {
    cvL: 'Catering & Food Services',
    total: 'Catering & Hospitality',
    reed: 6,
  },
  {
    cvL: 'Charity/Voluntary/Non Profit',
    total: 'Not For Profit, Charities',
    reed: 500,
  },
  {
    cvL: 'Construction',
    total: 'Construction',
    reed: 146,
  },
  {
    cvL: 'Consultancy',
    total: 'Management Consultancy',
    reed: 1678,
  },
  {
    cvL: 'Counseling',
    total: 'Social Services',
    reed: 1712,
  },
  {
    cvL: 'Customer service',
    total: 'Customer Services',
    reed: 66,
  },
  {
    cvL: 'Dental',
    total: 'Health, Nursing',
    reed: 484,
  },
  {
    cvL: 'Driving',
    total: 'Transport, Logistics',
    reed: 287,
  },
  {
    cvL: 'Education',
    total: 'Education',
    reed: 68,
  },
  {
    cvL: 'Electrical',
    total: 'Electronics',
    reed: 201,
  },
  {
    cvL: 'Electronic',
    total: 'Electronics',
    reed: 316,
  },
  {
    cvL: 'Emergency',
    total: 'Health, Nursing',
    reed: 1995,
  },
  {
    cvL: 'Engineering',
    total: 'Engineering, Manufacturing, Utilities',
    reed: 12,
  },
  {
    cvL: 'Entertainment',
    total: 'Media, New Media, Creative',
    reed: 940,
  },
  {
    cvL: 'Fashion',
    total: 'Media, New Media, Creative',
    reed: 1886,
  },
  {
    cvL: 'Financial',
    total: 'Banking, Insurance, Finance',
    reed: 117,
  },
  {
    cvL: 'Graduate',
    total: 'Graduate, Trainees',
    reed: 169,
  },
  {
    cvL: 'Health & Safety',
    total: 'Health, Nursing',
    reed: 465,
  },
  {
    cvL: 'Health medical',
    total: 'Health, Nursing',
    reed: 36,
  },
  {
    cvL: 'Home worker',
    total: 'Other',
    reed: 819,
  },
  {
    cvL: 'Hotel/Hospitality',
    total: 'Catering & Hospitality',
    reed: 6,
  },
  {
    cvL: 'Human-resources & Employment',
    total: 'Human Resources',
    reed: 24,
  },
  {
    cvL: 'IT/Internet/Technical',
    total: 'IT & Internet',
    reed: 52,
  },
  {
    cvL: 'Industrial',
    total: 'Engineering, Manufacturing, Utilities',
    reed: 1809,
  },
  {
    cvL: 'Insurance',
    total: 'Banking, Insurance, Finance',
    reed: 16,
  },
  {
    cvL: 'Journalism',
    total: 'Media, New Media, Creative',
    reed: 1659,
  },
  {
    cvL: 'Legal',
    total: 'Legal',
    reed: 101,
  },
  {
    cvL: 'Leisure',
    total: 'Travel, Leisure, Tourism',
    reed: 92,
  },
  {
    cvL: 'Management & Executive',
    total: 'Management Consultancy',
    reed: 1743,
  },
  {
    cvL: 'Manufacturing',
    total: 'Engineering, Manufacturing, Utilities',
    reed: 204,
  },
  {
    cvL: 'Marketing & PR',
    total: 'Marketing, Advertising, PR',
    reed: 18,
  },
  {
    cvL: 'Mechanical',
    total: 'Transport, Logistics',
    reed: 207,
  },
  {
    cvL: 'Media/Creative/Digital',
    total: 'Media, New Media, Creative',
    reed: 71,
  },
  {
    cvL: 'Nursing',
    total: 'Health, Nursing',
    reed: 100,
  },
  {
    cvL: 'Oil & Gas/Energy',
    total: 'Oil, Gas, Alternative Energy',
    reed: 1574,
  },
  {
    cvL: 'Other',
    total: 'Other',
    reed: 21,
  },
  {
    cvL: 'Packaging',
    total: 'Engineering, Manufacturing, Utilities',
    reed: 928,
  },
  {
    cvL: 'Pharmaceutical',
    total: 'Health, Nursing',
    reed: 1843,
  },
  {
    cvL: 'Photography',
    total: 'Media, New Media, Creative',
    reed: 1881,
  },
  {
    cvL: 'Printing',
    total: 'Media, New Media, Creative',
    reed: 1662,
  },
  {
    cvL: 'Property',
    total: 'Property',
    reed: 146,
  },
  {
    cvL: 'Public sector',
    total: 'Public Sector & Services',
    reed: 1919,
  },
  {
    cvL: 'Publishing',
    total: 'Media, New Media, Creative',
    reed: 2082,
  },
  {
    cvL: 'Purchasing/Procurement',
    total: 'Retail, Wholesale',
    reed: 27,
  },
  {
    cvL: 'Recruitment',
    total: 'Recruitment Sales',
    reed: 338,
  },
  {
    cvL: 'Retail',
    total: 'Retail, Wholesale',
    reed: 90,
  },
  {
    cvL: 'Sales',
    total: 'Sales',
    reed: 30,
  },
  {
    cvL: 'Scientific',
    total: 'Science',
    reed: 89,
  },
  {
    cvL: 'Secretarial & PA',
    total: 'Secretarial, PAs, Administration',
    reed: 3,
  },
  {
    cvL: 'Security',
    total: 'Other',
    reed: 1963,
  },
  {
    cvL: 'Social services',
    total: 'Social services',
    reed: 34,
  },
  {
    cvL: 'Teaching',
    total: 'Education',
    reed: 68,
  },
  {
    cvL: 'Telecomms',
    total: 'Telecommunications',
    reed: 52,
  },
  {
    cvL: 'Textiles',
    total: 'Media, New Media, Creative',
    reed: 912,
  },
  {
    cvL: 'Skilled & Unskilled',
    total: 'Other',
    reed: 470,
  },
  {
    cvL: 'Transport & Logistics',
    total: 'Transport, Logistics',
    reed: 11,
  },
  {
    cvL: 'Travel & Tourism',
    total: 'Travel, Leisure, Tourism',
    reed: 92,
  },
  {
    cvL: 'Utilities & Installation',
    total: 'Engineering, Manufacturing, Utilities',
    reed: 1696,
  },
  {
    cvL: 'Warehouse',
    total: 'Engineering, Manufacturing, Utilities',
    reed: 926,
  },
  {
    cvL: 'Waste management',
    total: 'Engineering, Manufacturing, Utilities',
    reed: 1873,
  }];

const CATERER_INDUSTRIES = [
    "Bar Management",
    "Catering Management",
    "Catering Staff",
    "Chef de Partie",
    "Chef Manager",
    "Chefs",
    "Commis Chef",
    "Conference/Banqueting Mgnt",
    "Development Chef",
    "Engineer",
    "Events Manager",
    "Executive Chef",
    "F & B Management",
    "Finance",
    "Head Chef",
    "Hotel Management",
    "Housekeeping",
    "Human Resources",
    "IT Systems",
    "Kitchen Manager",
    "Leisure Management",
    "Leisure Staff",
    "Operation Manager",
    "Other",
    "Pastry Chef",
    "Porter",
    "Pub Management",
    "Pub/Bar Staff",
    "Reception/Concierge",
    "Reservation Manager",
    "Restaurant Management",
    "Sales & Marketing",
    "Sommelier",
    "Sous Chef",
    "Training",
    "Waiting Staff"
];

const DURATIONS = [
  {
    name: '24 hours',
    value: 1
  },
  {
    name: '3 days',
    value: 3
  },
  {
    name: '7 days',
    value: 7
  },
  {
    name: '14 days',
    value: 14
  },
  {
    name: '28 days',
    value: 28
  },
  {
    name: '2 months',
    value: 60
  },
  {
    name: '3 months',
    value: 91
  },
  {
    name: '6 months',
    value: 183
  },
  {
    name: '1 year',
    value: 365
  },
  {
    name: '2 years',
    value: 730
  },
  {
    name: 'All time',
    value: ''
  }];

  const TYPES = [{
    name: 'All',
    value: ''
  },
  {
    name: 'Permanent',
    value: 'perm'
  },
  {
    name: 'Contract',
    value: 'cont'
  },
  {
    name: 'Temporary',
    value: 'temp'
  },
  {
    name: 'Part time',
    value: 'part'
  }];

const DISTANCES = [5, 10, 25, 50, 100, 800];

  export { INDUSTRIES, DURATIONS, TYPES, DISTANCES, CATERER_INDUSTRIES }
