import React, { useEffect } from 'react';
import './styles.scss';
import CandidateResults from '../../../components/MainApp/Dashboard/CandidateResults';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Badge } from 'react-bootstrap';
import { useStateValue } from '../../../state';
import Loader from 'react-loader-spinner';
import cvLibrary from '../../../images/cv-library.png';
import reed from '../../../images/reed.png';
import total from '../../../images/total.png';
import caterer from '../../../images/caterer.png';
import cw from '../../../images/cw.png';

const Tabs = ({
  data,
  catererData,
  loading,
  totalRecords,
  totalData,
  reedData,
  cwData,
  totalTotalRecords,
  totalCwRecords,
  totalCatererRecords,
  totalReedRecords,
  cvLibraryError,
  catererError,
  totalError,
  reedError,
  handleSubmit,
  cvLibraryOffset,
  offset,
  setOffset,
  allTabExtraData
}) => {
  const [{ boardCfgs, activeBoardTab, catererLoading, cvLibraryLoading, reedLoading, totalLoading, cwLoading, allLoading, cvlFull, catererFull, totalFull, cwFull, reedFull }, dispatch] = useStateValue();

  useEffect(() => {
    const activeT = boardCfgs.find(x => 1 === parseInt(x.active));
    dispatch({
      type: 'SET_ACTIVE_TAB',
      value: 1
    })
  }, [boardCfgs]);

  const toggleTab = (tab, myData) => {
    if(!myData && tab !== 99) {
      return;
    }
    (data || catererData || totalData || reedData || cwData) &&
      dispatch({
        type: 'UPDATE_ACTIVE_BOARD_TAB',
        tab: parseInt(tab)
      })
  }

  const totalRecordsArr = [{
      id: 1,
      totalRecords: totalRecords && totalRecords.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    {
      id: 2,
      totalRecords: totalReedRecords.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    {
      id: 3,
      totalRecords: totalTotalRecords.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    {
      id: 4,
      totalRecords: totalCatererRecords.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    {
      id: 5,
      totalRecords: totalCwRecords.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    // {
    //   id: 99,
    //   totalRecords: allTabTot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // }
  ]

  let activeBoards = 0;
  let tot = [];
  if(boardCfgs.find((cfg) => cfg.boardId == 1 && cfg.active == 1)) {
    cvlFull && tot.push(cvlFull);
  }

  if(boardCfgs.find((cfg) => cfg.boardId == 2 && cfg.active == 1)) {
    reedFull && tot.push(reedFull);
  }

  if(boardCfgs.find((cfg) => cfg.boardId == 3 && cfg.active == 1)) {
    totalFull && tot.push(totalFull);
  }

  if(boardCfgs.find((cfg) => cfg.boardId == 4 && cfg.active == 1)) {
    catererFull && tot.push(catererFull);
  }

  if(boardCfgs.find((cfg) => cfg.boardId == 5 && cfg.active == 1)) {
    cwFull && tot.push(cwFull);
  }

  let allTabTot = 0;
  let allTabRecordSet = [];

  tot.map(t => {
    allTabTot += t.total;
    allTabRecordSet = allTabRecordSet.concat(t.data)
  });

  // let grandTotal = parseInt(totalRecordsArr[0].totalRecords.replace(/\,/g,""))+parseInt(totalRecordsArr[1].totalRecords.replace(/\,/g,""));
  // grandTotal = isNaN(grandTotal) ? grandTotal = 0 : grandTotal;
  // grandTotal = grandTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return <div className="tabsWrapper">
          <div className="tabsContainer">
            <div className="tabsHeader">
              <ul className="tabs">
                {
                  boardCfgs.map((board, i) => {
                    let myLoading = loading.find(x => x.id === parseInt(board.boardId));
                    const myTotal = totalRecordsArr.find(x => x.id === parseInt(board.boardId));
                    parseInt(board.active) === 1 && activeBoards++;
                    const className1 = activeBoardTab === parseInt(board.boardId) && 'active';
                    const className2 = !data ? ' inactive' : ' ';

                    let img;
                    let myData;
                    switch(parseInt(board.boardId)) {
                      case 1:
                       img = cvLibrary;
                       myData = data;
                       myLoading = cvLibraryLoading;
                      break;
                      case 2:
                        img = reed;
                        myData = reedData;
                        myLoading = reedLoading;
                      break;
                      case 3:
                        img = total;
                        myData = totalData
                        myLoading = totalLoading;
                      break;
                      case 4:
                        img = caterer;
                        myData = catererData;
                        myLoading = catererLoading;
                      break;
                      case 5:
                        img = cw;
                        myData = cwData;
                        myLoading = cwLoading
                      break;
                      default:
                        img = cvLibrary;
                    }


                    return parseInt(board.active) === 1 && <li key={board.boardName} className={className1 + className2} onClick={() => toggleTab(board.boardId, myData)}>
                            {
                              !myLoading ?
                                <>
                                  <span className={img === total || img === cw || img === caterer ? 'total' : ''}>
                                    <img alt={board.boardName} src={img}  className={!myData ? 'fadeDisabled' : ''} />
                                  </span>
                                  {
                                    myData && <Badge variant="secondary" className={img===total ? 'totalBadge' : ''}><FontAwesomeIcon icon={['fas', 'user']} size="sm" /> {myTotal && myTotal.totalRecords}</Badge>
                                  }
                                </>
                              :
                                <div className="spinnerContainerTab">
                                  <Loader
                                     type="ThreeDots"
                                     color="#02547d"
                                     height={40}
                                     width={40}
                                     timeout={99000}
                                  />
                                </div>
                            }
                            </li>
                  })
                }
                {
                  activeBoards > 1 &&
                  <li onClick={() => { toggleTab(99) }} className={activeBoardTab === 99 ? 'all active inactive' : 'all'}>
                    {
                      !allLoading ?
                        <div className="allTab">
                          <div className={(catererData) ? '' : 'fadeDisabled inactive'}><FontAwesomeIcon icon={['far', 'user-friends']} size="lg" /> All
                            {
                               ((catererData && catererData.length) || (totalData && totalData.length) || (cwData && cwData.length) || data) && <Badge variant="secondary"><FontAwesomeIcon icon={['fas', 'user']} size="sm" />{!isNaN(allTabTot )&& allTabTot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Badge>
                            }
                          </div>
                        </div>
                      :
                      <div className="spinnerContainerTab">
                        <Loader
                           type="ThreeDots"
                           color="#02547d"
                           height={40}
                           width={40}
                           timeout={99000}
                        />
                      </div>
                    }
                  </li>
                }

              </ul>
            </div>

            {
              activeBoardTab === 1 &&
                <div className="tabsContent">
                  {
                    cvLibraryError ?
                    <div className="half">
                      <Alert variant="danger">
                        <strong>Error:</strong> {cvLibraryError}
                      </Alert>
                    </div>
                    :
                    <CandidateResults
                      handleSubmit={handleSubmit}
                      data={data}
                      boardId={1}
                      loading={cvLibraryLoading}
                      totalRecords={totalRecords}
                      offset={offset}
                      setOffset={setOffset} />
                    }
                </div>
              }
            {
              activeBoardTab === 2 &&
              <div className="tabsContent">
                {
                  reedError ?
                  <div className="half">
                    <Alert variant="danger">
                      <strong>Error:</strong> {reedError} Please check your credentials are correct.
                    </Alert>
                  </div>
                  :
                  <CandidateResults
                    handleSubmit={handleSubmit}
                    data={reedData}
                    boardId={2}
                    loading={reedLoading}
                    totalRecords={totalReedRecords}
                    offset={offset}
                    setOffset={setOffset} />
                  }
              </div>
            }
            {
              activeBoardTab === 4 &&
                <div className="tabsContent">
                {
                  catererError ?
                  <div className="half">
                    <Alert variant="danger">
                      <strong>Error:</strong> {catererError}
                    </Alert>
                  </div>
                  :
                  <CandidateResults
                    handleSubmit={handleSubmit}
                    data={catererData}
                    loading={catererLoading}
                    boardId={4}
                    totalRecords={totalCatererRecords}
                    offset={offset}
                    setOffset={setOffset}
                    />
                  }
                </div>
            }
            {
              activeBoardTab === 3 &&
                <div className="tabsContent">
                {
                  catererError ?
                  <div className="half">
                    <Alert variant="danger">
                      <strong>Error:</strong> {totalError}
                    </Alert>
                  </div>
                  :
                  <CandidateResults
                    handleSubmit={handleSubmit}
                    data={totalData}
                    loading={totalLoading}
                    boardId={3}
                    totalRecords={totalTotalRecords}
                    offset={offset}
                    setOffset={setOffset}
                     />
                  }
                </div>
            }
            {
              activeBoardTab === 5 &&
              <div className="tabsContent">
              {
                catererError ?
                <div className="half">
                  <Alert variant="danger">
                    <strong>Error:</strong> {catererError}
                  </Alert>
                </div>
                :
                <CandidateResults
                  handleSubmit={handleSubmit}
                  data={cwData}
                  loading={cwLoading}
                  boardId={5}
                  totalRecords={totalCwRecords}
                  offset={offset}
                  setOffset={setOffset}
                  />
                }
              </div>
            }
            {
              activeBoardTab === 99 &&
                <div className="tabsContent">
                <CandidateResults
                  handleSubmit={handleSubmit}
                  data={allTabRecordSet}
                  loading={totalLoading}
                  boardId={99}
                  totalRecords={allTabTot}
                  offset={offset}
                  setOffset={setOffset}
                   />
                </div>
            }

          </div>
        </div>
}

export default Tabs;
