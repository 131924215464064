import React, { useState } from 'react';
import ReactShadowRoot from 'react-shadow-root';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Blob from 'blob';
import Loader from 'react-loader-spinner';
import {
  faUnlockAlt,
  faFileDownload,
  faPhone,
  faEnvelope,
  faMobileAlt
} from '@fortawesome/free-solid-svg-icons';
import { useStateValue } from '../../../../../state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import { Table, Button, DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';

const CvLibraryModal = ({candidateData, unlocked, downloadCv, unlockCv, currentTotalCandidate, shortlists, addToShortlist}) => {
  const [loading, setLoading] = useState();
  const [cvPreview, setCvPreview] = useState(false);
  const [loadingCv, setLoadingCv] = useState(false);
  const [{ boardCfgs }] = useStateValue();
  const [candidateContactInfo, setCandidateContactInfo] = useState();
  const total = boardCfgs.find(x => 3 === parseInt(x.boardId));

  const unlockCatererCv = async () => {
    setLoading(true);

    const resultContactInfo = await axios({
      url: `${process.env.REACT_APP_API}/totalProfileContactInfo`,
      method: 'post',
      data: {
        id: currentTotalCandidate.Record_Id._text,
        totU: total.user,
        totP: total.pass
      }
    });

    resultContactInfo.data.boardId = 3;

    setCandidateContactInfo(resultContactInfo.data);

    const result = await axios({
      url: `${process.env.REACT_APP_API}/totalProfileTeaser`,
      method: 'post',
      data: {
        id: currentTotalCandidate.Record_Id._text,
        totU: total.user,
        totP: total.pass
      }
    });
    setLoading(false);
    setCvPreview(result.data);
  }


  const downloadCatererCv = async () => {
    setLoadingCv(true);
    // const result = await axios({
    //   url: `${process.env.REACT_APP_API}/downloadCatererCv`,
    //   method: 'post',
    //   data: {
    //     id: currentCatererProfile.Record_Id._text
    //   }
    // });
    //
    // var blob = new Blob([result.data], {type: "application/octet-stream"});
    // var fileName = "test.zip";
    // // download(blob, fileName, 'application/zip');
    // saveAs(blob, fileName);

    const result = await axios({
      url: `${process.env.REACT_APP_API}/totalToken`,
      method: 'post',
      data: {
        totU: total.user,
        totP: total.pass
      }
    });

    const token = result.data;

    let config = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
        }
    }

    axios
    .get(`https://recruiter.totaljobs.tjgtest.com/CandidateSearchApi/cv/${currentTotalCandidate.Record_Id._text}`, config)
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/octet-stream'
      })
      const filename = 'download.zip'
      saveAs(blob, filename)
      setLoadingCv(false);
    })
    .catch((e) => {
      // Handle error
    })

  }

  return <>
  {
    1===0 ?
     <div className="spinnerContainer spinnerModal">
       <h4>Fetching CV</h4>
       <Loader
          type="ThreeDots"
          color="#02547d"
          height={100}
          width={100}
          timeout={940000}
       />
    </div>
    :
  <div className="candidateResults">
  {
    loading ?
      <div className="spinnerContainer spinnerModal">
        <h4>Fetching CV</h4>
        <Loader
           type="ThreeDots"
           color="#02547d"
           height={100}
           width={100}
           timeout={40000}
        />
     </div>
     :
     <>
     <h3>{currentTotalCandidate.Name._text}</h3>
     <div className="modalBasicInfo">
       <Table striped bordered hover>
         <thead>
           <tr>
             <th>Location</th>
             <th>Current Job</th>
             <th>Desired Job</th>
             <th>Submitted</th>
             <th>Modified</th>
           </tr>
           <tr>
             <td>{currentTotalCandidate.Town._text}</td>
             <td>{currentTotalCandidate.CurrentJobTitle._text}</td>
             <td>{currentTotalCandidate.other.DesiredJobTitle}</td>
             <td>{currentTotalCandidate.other.RegisteredDate}</td>
             <td>{currentTotalCandidate.other.LastUpdatedDate}</td>
           </tr>
         </thead>
         <tbody>
           {
             candidateContactInfo &&
             <tr>
               <td colSpan="2"><FontAwesomeIcon icon={faPhone} color="black" size="1x" />: &nbsp;&nbsp;{candidateContactInfo.LandlinePhone} &nbsp;&nbsp;&nbsp;&nbsp;</td>
               <td colSpan="2"><FontAwesomeIcon icon={faMobileAlt} color="black" size="1x" />: &nbsp;&nbsp; {candidateContactInfo.MobilePhone} &nbsp;&nbsp;&nbsp;&nbsp;</td>
               <td colSpan="2"><FontAwesomeIcon icon={faEnvelope} color="black" size="1x" />: &nbsp;&nbsp; {candidateContactInfo.Email}</td>
             </tr>
           }
         </tbody>
       </Table>

          <div className="catererProfile totalProfile">
            {
              cvPreview ?
              <ReactShadowRoot>
                <div dangerouslySetInnerHTML={{__html: cvPreview}} />
              </ReactShadowRoot>
              :
              <>
                <h5>Personal Summary</h5>
                <p>{currentTotalCandidate.other.PersonalSummary ? currentTotalCandidate.other.PersonalSummary: 'Not provided'}</p>
                <h5>CV Snippet</h5>
                <p>{currentTotalCandidate.other.CvSnippet}</p>
                <h5>Key Skills</h5>
                <p>{currentTotalCandidate.other.KeySkills ? currentTotalCandidate.other.KeySkills : 'Not provided'}</p>
              </>
            }
          </div>
     </div>
     <div className="modalFooter">
       {
         cvPreview ?
           <>
           <DropdownButton as={ButtonGroup} title="Shortlist candidate" id="bg-vertical-dropdown-4">
             {
               shortlists ? shortlists.map((sl) => {
                 return <Dropdown.Item eventKey="999" onClick={() => addToShortlist(sl.shortlistId, candidateContactInfo)}>
                           {sl.shortlistName}
                         </Dropdown.Item>
               })
               :
               <Dropdown.Item eventKey="999" disabled={true}>
                 You have not created any shortlists yet.
               </Dropdown.Item>
             }
           </DropdownButton>
           <Button variant="success" disabled={loadingCv && true} className="downloadCvButton" onClick={() => {downloadCatererCv()}}>
              {
                !loadingCv ?
                  <>
                  <FontAwesomeIcon icon={faFileDownload} color="white" size="1x" />&nbsp; Download CV
                  </>
                :
                  <Loader
                     type="ThreeDots"
                     color="#fff"
                     height={10}
                     width={100}
                     timeout={40000}
                  />
                }
                </Button>

           </>
           :
           <Button variant="primary" className="viewCv" onClick={() => {unlockCatererCv()}}><FontAwesomeIcon icon={faUnlockAlt} color="white" size="1x" />&nbsp; Unlock CV</Button>
       }
     </div>
   </>
  }
  </div>
  }
</>
}

export default CvLibraryModal;
