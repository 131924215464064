import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useStateValue } from '../../../../../state';
import axios from 'axios';

const DeleteSearch = ({currentSearch, closeModal}) => {
  const [{ user2 }, dispatch] = useStateValue();

  const confirmDelete = async () => {
    const result = await axios({
      url: `${process.env.REACT_APP_API}/deleteSavedSearch`,
      method: 'post',
      data: {
        user: user2.userId,
        sid: user2.sessionID,
        id: currentSearch.savedSearchId
      }
    });
    result.data = JSON.parse(result.data);
    dispatch({
      type: 'SET_SAVED_SEARCHES',
      data: result.data
    })
    closeModal('Saved search deleted');
    // refresh();
  }

  return (
    <>
      <h1>Delete saved search '{currentSearch.name}'?</h1>
      <p>Are you sure you want to delete this saved search?</p>
      <div className="modalFooter">
        <Button variant="success" className="activateBoard" onClick={() => { confirmDelete()} }><FontAwesomeIcon icon={faCheck} color="white" size="1x" />&nbsp; Confirm</Button>
        <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
      </div>
    </>
  )
}

export default DeleteSearch;
