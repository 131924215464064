import React, { useState } from 'react';
import { Form, Col, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import {
  faTimes,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const EditUser = ({closeModal, record, user, refresh, clientsList}) => {
  const [password, setPassword] = useState('**********');
  const [firstName, setFirstName] = useState(record.firstName);
  const [lastName, setLastName] = useState(record.lastName);
  const [email, setEmail] = useState(record.email);
  const [client, setClient] = useState(record.clientId);
  const [access, setAccess] = useState(record.access);
  const [error, setError] = useState();
  const [clients] = useState(clientsList);



  const confirmEdit = async () => {
    if(!firstName || !lastName || !email || !client || !password) {
      setError('Please complete all fields');
      return;
    }

    const result = await axios({
      url: `${process.env.REACT_APP_API}/editUser`,
      method: 'post',
      data: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        access: access,
        client: client,
        sid: user.sessionID,
        user: user.email,
        userId: record.userId
      }
    });

    if(result.data.response) {
      setError(result.data.response);
    } else {
      toast("User details successfully updated");
      refresh();
      closeModal();
    }
  }

  return (
    <>
      <h1>Edit User</h1>
      {
        error &&
        <Alert variant="danger">
          <strong>Error:</strong> {error}
        </Alert>
      }
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="firstName" md="6">
              <Form.Label>First name</Form.Label>
              <Form.Control type="text" placeholder="First name" value={firstName} onChange={(e) => { setFirstName(e.target.value)}} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="lastName" md="6">
              <Form.Label>Surname</Form.Label>
              <Form.Control type="text" placeholder="Surname" value={lastName} onChange={(e) => { setLastName(e.target.value)}} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="ownerEmail" md="6">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Email address" value={email} onChange={(e) => { setEmail(e.target.value)}} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="ownerPassword" md="6">
              <Form.Label>Password</Form.Label>
              <Form.Control type="text" placeholder="Password" value={password} disabled onChange={(e) => { setPassword(e.target.value)}} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridState" md="6">
              <Form.Label>Client</Form.Label>
              <Form.Control as="select" value={client} onChange={(e) => { setClient(e.target.value)}}>
                <option>Please Select</option>
                  {
                    clients.map((c) => {
                      return <option value={c.myid}>{c.clientName}</option>
                    })
                  }
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridState" md="6">
              <Form.Label>Access Level</Form.Label>
              <Form.Control as="select" value={access} onChange={(e) => { setAccess(e.target.value)}}>
                <option value="1">Basic User</option>
                <option value="2">Administrator</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

        </Form>
        <div className="modalFooter">
          <Button variant="success" className="activateBoard" onClick={() => { confirmEdit()} }><FontAwesomeIcon icon={faPlus} color="white" size="1x" />&nbsp; Update</Button>
          <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
      </div>
    </>
  )
}

export default EditUser;
