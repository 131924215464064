import React, { useState } from 'react';
import { Form, Col, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  faTimes,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

const EditClient = ({closeModal, user, refresh, selectedClient}) => {
  const [clientName, setClientName] = useState(selectedClient.clientName);
  const [userLimit, setUserLimit] = useState(selectedClient.userLimit)
  const [error, setError] = useState();


  const confirmEdit = async () => {
    if(!clientName || !userLimit) {
      setError('Please complete all fields');
      return;
    }

    const result = await axios({
      url: `${process.env.REACT_APP_API}/editClient`,
      method: 'post',
      data: {
        sid: user.sessionID,
        user: user.email,
        id: selectedClient.myid,
        clientName,
        userLimit
      }
    });

    if(result.data.response) {
      setError(result.data.response);
    } else {
      toast('Client successfully updated');
      refresh();
      closeModal();
    }
  }

  return (
    <>
      <h1>Edit Client</h1>
        {
          error &&
          <Alert variant="danger">
            <strong>Error:</strong> {error}
          </Alert>
        }

        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="clientName" md="6">
              <Form.Label>Client Name</Form.Label>
              <Form.Control type="text" placeholder="Client Name" value={clientName} onChange={(e) => { setClientName(e.target.value)}} />
            </Form.Group>

            <Form.Group as={Col} controlId="ownerPassword" md="2">
              <Form.Label>Max users</Form.Label>
              <Form.Control type="text" value={userLimit} onChange={(e) => { setUserLimit(e.target.value)}} />
            </Form.Group>
          </Form.Row>
        </Form>
      <div className="modalFooter">
        <Button variant="success" className="activateBoard" onClick={() => { confirmEdit()} }><FontAwesomeIcon icon={faPlus} color="white" size="1x" />&nbsp; Update</Button>
        <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
      </div>
    </>
  )
}

export default EditClient;
