import React, { useState } from "react";
import "./style.scss";
import { Alert, Form, Button } from 'react-bootstrap';
import logo from '../../images/logo.svg';
import Loader from 'react-loader-spinner';
import { useStateValue } from '../../state';
import axios from 'axios';

const Login = ({ error, auth, disableLoginButton }) => {
  const [{ }, dispatch] = useStateValue();
  const [email, setEmail] = useState('');
  const [disableLoginButton2, setDisableLoginButton2] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    login(email, password);
  }

  const login = async (u, p) => {
    setDisableLoginButton2(true);
    const result = await axios({
      url: `${process.env.REACT_APP_API}/authenticate`,
      method: 'post',
      data: {
        user: u,
        password: p
      }
    });

    result.data = JSON.parse(result.data);
    if(result.data.status === 'ERR') {
      setDisableLoginButton2(false);
      setErrorMessage(true);
    } else if (result.data.status === 'OK') {
        const resultBcfg = await axios({
          url: `${process.env.REACT_APP_API}/fetchBoardConfigs`,
          method: 'post',
          data: {
            sid: result.data.sessionID,
            user: email
          }
        });
        resultBcfg.data = JSON.parse(resultBcfg.data);

      setDisableLoginButton2(false);
      localStorage.setItem('loggedIn', true);
      localStorage.setItem('user', u);
      localStorage.setItem('vpSessionId', result.data.sessionID);
      localStorage.setItem('sessionID', result.data.cvLibrarySession);

      dispatch({
        type: 'AUTHENTICATE_USER_REQUEST',
        data: result.data,
        boardCfgs: resultBcfg.data
      })

    }
  }

  const handleChange = (e) => {
    e.target.name === 'email' ? setEmail(e.target.value) : setPassword(e.target.value);
  }

  return (
    <div className="loginWrapper">
      <div className="gradient">
        <div className="loginContainer">
          <img alt="Vacancy Poster Candidate Search Portal" src={logo} className="vpLogo"/>
          <h1>Sign in to your account</h1>
          <div className="form">
            <Form onSubmit={(e) => { handleSubmit(e)} }>
              {
                errorMessage &&
                <Alert variant="danger">
                  Invalid username and/or password
                </Alert>
              }

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" name="email" placeholder="Enter email" value={email} onChange={(e) => { handleChange(e)} } />
              </Form.Group>

              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" name="password" placeholder="Password" value={password} onChange={(e) => { handleChange(e)} } />
              </Form.Group>

              <Button variant="primary" type="submit" className="loginButton" disabled={disableLoginButton2}>
                {
                  disableLoginButton2 ?
                    <Loader
                       type="ThreeDots"
                       color="#fff"
                       height={10}
                       width={50}
                       timeout={912000}
                    />
                  : 'Sign in'
                }
              </Button>
            </Form>
          </div>
        </div>
    </div>
    </div>
  );
}

export default Login;
