import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactShadowRoot from 'react-shadow-root';
import { Table, Button, DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';
import {
  faUnlockAlt,
  faFileDownload,
  faPhone,
  faEnvelope,
  faMobileAlt
} from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';

const CvLibraryModal = ({candidateData, unlocked, downloadCv, unlockCv, shortlists, addToShortlist}) => {
  return <>
  {
    !candidateData.cv ?
     <div className="spinnerContainer spinnerModal">
       <h4>Fetching CV</h4>
       <Loader
          type="ThreeDots"
          color="#02547d"
          height={100}
          width={100}
          timeout={40000} //3 secs
       />
    </div>
    :
  <div className="candidateResults">
  <h3>{candidateData.name}</h3>
    <div className="modalBasicInfo">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Location</th>
            <th>Current Job</th>
            <th>Desired Job</th>
            <th>Submitted</th>
            <th>Modified</th>
            <th>Available</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>{candidateData.town}</td>
          <td>{candidateData.currentJobTitle}</td>
          <td>{candidateData.desiredJobTitle}</td>
          <td>{candidateData.dateSubmitted}</td>
          <td>{candidateData.lastModified}</td>
          <td>{candidateData.dateAvailable}</td>
        </tr>
        {
          unlocked &&
          <tr>
            <td colSpan="2"><FontAwesomeIcon icon={faPhone} color="black" size="1x" />: &nbsp;&nbsp;{candidateData.phone} &nbsp;&nbsp;&nbsp;&nbsp;</td>
            <td colSpan="2"><FontAwesomeIcon icon={faMobileAlt} color="black" size="1x" />: &nbsp;&nbsp; {candidateData.mobile} &nbsp;&nbsp;&nbsp;&nbsp;</td>
            <td colSpan="2"><FontAwesomeIcon icon={faEnvelope} color="black" size="1x" />: &nbsp;&nbsp; {candidateData.email}</td>
          </tr>
        }
        </tbody>
      </Table>
    </div>
    <div className="scrollbar scrollbar2" id="style-5">
      <div className="force-overflow">
        <div> {/* The shadow root will be attached to this DIV */}
            <ReactShadowRoot>
              <div dangerouslySetInnerHTML={{__html: candidateData.cv}} />
            </ReactShadowRoot>
          </div>

      </div>
    </div>

  <div className="modalFooter">
    {
      unlocked ?
        <>
        <DropdownButton as={ButtonGroup} title="Shortlist candidate" id="bg-vertical-dropdown-4">
          {
            shortlists ? shortlists.map((sl) => {
              return <Dropdown.Item key={sl.shortlistId} eventKey="999" onClick={() => addToShortlist(sl.shortlistId, candidateData)}>
                        {sl.shortlistName}
                      </Dropdown.Item>
            })
            :
            <Dropdown.Item eventKey="999" disabled={true}>
              You have not created any shortlists yet.
            </Dropdown.Item>
          }
        </DropdownButton>
        <Button variant="success" className="downloadCvButton" onClick={() => {downloadCv()}}><FontAwesomeIcon icon={faFileDownload} color="white" size="1x" />&nbsp; Download CV</Button>
        </>
        :
        <Button variant="primary" className="viewCv" onClick={() => {unlockCv()}}><FontAwesomeIcon icon={faUnlockAlt} color="white" size="1x" />&nbsp; Unlock CV</Button>
    }

  </div>
</div>
    }
    </>
}

export default CvLibraryModal;
