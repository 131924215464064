import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStateValue } from '../../../../../state';
import axios from 'axios';
import {
  faTimes,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

const EditClient = ({closeModal, selectedBoard, updateCredentials, confirmSave}) => {
  const [username, setUsername] = useState(selectedBoard.user);
  const [password, setPassword] = useState(selectedBoard.pass);
  const [email, setEmail] = useState(selectedBoard.em);
  const [{ user2 }] = useStateValue();

  const confirmEdit = async () => {
    await axios({
      url: `${process.env.REACT_APP_API}/editBoardConfig`,
      method: 'post',
      data: {
        sid: user2.sessionID,
        user: user2.email,
        username,
        password,
        email,
        boardId: selectedBoard.boardId,
        id: selectedBoard.id
      }
    });

    selectedBoard.em  = email;
    selectedBoard.pass  = password;
    selectedBoard.user  = username;

    closeModal();
    confirmSave('Credentials saved');
  }

  return (
    <>
      <h1>Edit {selectedBoard.boardName} Credentials</h1>
        <Form autoComplete="off">
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" placeholder="Enter username" autoComplete="off" value={username} onChange={(e) => { setUsername(e.target.value) }} />
          </Form.Group>
          {
            parseInt(selectedBoard.boardId) === 1 &&
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="text" placeholder="Enter email" autoComplete="off" value={email} onChange={(e) => { setEmail(e.target.value) }} />
            </Form.Group>
          }
          <Form.Group controlId="formBasicEmail">
            <Form.Label>{parseInt(selectedBoard.boardId) === 2 ? 'Posting key' : 'Password'}</Form.Label>
            <Form.Control type="password" placeholder="Enter password" autoComplete="off" value={password} onChange={(e) => { setPassword(e.target.value) }} />
          </Form.Group>
          <div className="modalFooter">
            <Button variant="success" className="activateBoard" onClick={() => { confirmEdit()} }><FontAwesomeIcon icon={faCheck} color="white" size="1x" />&nbsp; Save</Button>
            <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
          </div>
      </Form>
    </>
  )
}

export default EditClient;
