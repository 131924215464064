import React, { useState } from 'react';
import { Form, Col, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import RandomString from 'randomstring';
import {
  faTimes,
  faRandom,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const AddUser = ({closeModal, refresh, user, clientsList}) => {
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [client, setClient] = useState();
  const [access, setAccess] = useState(1);
  const [error, setError] = useState();
  const [clients] = useState(clientsList);

  const addUserRequest = async () => {
    const c = user.access < 3 ? user.access : client;
    if(!firstName || !lastName || !email || !c || !password) {
      setError('Please complete all fields');
      return;
    }

    const result = await axios({
      url: `${process.env.REACT_APP_API}/addUser`,
      method: 'post',
      data: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        access: access,
        client: c,
        sid: user.sessionID,
        user: user.email
      }
    });

    if(result.data.response) {
      setError(result.data.response);
    } else {
      toast("User successfully added");
      closeModal();
      refresh();
    }
  }

  return (
    <>
      <h1>Add User</h1>
      {
        error &&
        <Alert variant="danger">
          <strong>Error:</strong> {error}
        </Alert>
      }
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="firstName" md="6">
              <Form.Label>First name</Form.Label>
              <Form.Control type="text" placeholder="First name" value={firstName} onChange={(e) => { setFirstName(e.target.value)}} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="lastName" md="6">
              <Form.Label>Surname</Form.Label>
              <Form.Control type="text" placeholder="Surname" value={lastName} onChange={(e) => { setLastName(e.target.value)}} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="ownerEmail" md="6">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Email address" value={email} onChange={(e) => { setEmail(e.target.value)}} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="ownerPassword" md="6">
              <Form.Label>Owner Password</Form.Label>
              <Form.Control type="text" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value)}} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword" md="2">
              <Button variant="success" className="activateBoard generatePw" onClick={() => { setPassword(RandomString.generate(10))}}><FontAwesomeIcon icon={faRandom} color="white" size="1x" />&nbsp; Generate</Button>
            </Form.Group>
          </Form.Row>

          <div style={  { display: user.access < 3 ? 'none' : 'block' }}>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridState" md="6">
                <Form.Label>Client</Form.Label>
                <Form.Control as="select" value={client} onChange={(e) => { setClient(e.target.value)}}>
                  <option>Please Select</option>
                  {
                    parseInt(user?.access) === 3 ? clients.map((c) => {
                      return <option key={c.clientName} value={c.myid}>{c.clientName}</option>
                    })
                    :
                    <option value={user?.clientId} selected>{user?.clientId}</option>
                  }
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </div>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridState" md="6">
              <Form.Label>Access Level</Form.Label>
              <Form.Control as="select" value={access} onChange={(e) => { setAccess(e.target.value)}}>
                <option value="1">Basic User</option>
                <option value="2">Administrator</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

        </Form>
        <div className="modalFooter">
          <Button variant="success" className="activateBoard" onClick={() => { addUserRequest()} }><FontAwesomeIcon icon={faPlus} color="white" size="1x" />&nbsp; Add</Button>
          <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
      </div>
    </>
  )
}

export default AddUser;
