import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import CvLibraryModal from './CvLibraryModal';
import CatererModal from './CatererModal';
import TotalModal from './TotalModal';
import ReedModal from './ReedModal';
import { toast } from 'react-toastify';
import CwModal from './CwModal';
import { rebuildSlObj } from '../../../../utils.js'
import { useStateValue } from '../../../../state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIdBadge,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';
import download from 'downloadjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import utf8 from 'utf8';
// import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import './styles.scss';
import cvLibrary from '../../../../images/cv-library.png';
import reed from '../../../../images/reed.png';
import total from '../../../../images/total.png';
import caterer from '../../../../images/caterer.png';
import cw from '../../../../images/cw.png';

const CandidateResults = ({data, loading, totalRecords, handleSubmit, CvLibraryCredentials, offset, setOffset, boardId}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [{ user2, boardCfgs }, dispatch] = useStateValue();
  const [currentCID, setCurrentCID] = useState('');
  const [unlocked, setUnlocked] = useState(false);
  const [candidateData, setCandidateData] = useState('');
  const [shortlists, setShortlists] = useState();
  const [allTabCurrent, setAllTabCurrent] = useState();
  const [currentTotalCandidate, setCurrentTotalCandidate] = useState();
  const [currentReedCandidate, setCurrentReedCandidate] = useState();

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  const addToShortlist = async (sl, profile, reed) => {
    let p;
    if (reed) {
      reed.Email = profile.Email;
      reed.LandlinePhone = profile.LandlinePhone;
      reed.MobilePhone = profile.MobilePhone;
      p = rebuildSlObj(reed);
    } else {
      p = rebuildSlObj(profile);
    }


    await axios({
      url: `${process.env.REACT_APP_API}/addItemToShortlist`,
      method: 'post',
      data: {
        id: user2.userId,
        sid: user2.sessionID,
        boardId: p.boardId ? p.boardId : 1,
        name: p.name,
        shortlistId: sl,
        location: p.town,
        lastModified: p.lastModified,
        mobile: p.mobile,
        phone: p.phone,
        email: p.email
      }
    });
    toast("Candidate added to shortlist");
  }

  useEffect(() => {
    const fetchShortlists = async (email, sessionID) => {
      const result = await axios({
        url: `${process.env.REACT_APP_API}/fetchShortlists`,
        method: 'post',
        data: {
          id: user2.userId,
          sid: user2.sessionID
        }
      });
      setShortlists(JSON.parse(result.data));
    }
    fetchShortlists();
  }, []);

  const cvl = boardCfgs.find(x => 1 === parseInt(x.boardId));

  const fetchMoreData = () => {
    // boardId === 1 &&
    //   dispatch({
    //     type: 'UPDATE_CV_LIBRARY_OFFSET',
    //     value: offset+25
    //   })
    //
    // boardId === 3 &&
    //   dispatch({
    //     type: 'UPDATE_TOTAL_OFFSET',
    //     value: offset+25
    //   })

    const thisBoardOffset = offset.find(x => boardId === parseInt(x.boardId));
    const newOffsetArr = offset.filter(x => boardId !== parseInt(x.boardId));

    thisBoardOffset.offset += 25;
    newOffsetArr.push(thisBoardOffset)
    setOffset(newOffsetArr);
  }

  Modal.setAppElement('#app');

  const getReedPartial = async (row) => {
    const search = JSON.parse(localStorage.getItem('formData'));
    const keywords = search.keywords;
    const result = await axios({
      url: `${process.env.REACT_APP_API}/reedPartial`,
      method: 'post',
      data: {
        id: row.Record_Id._text,
        u: reed.username,
        p: reed.password,
        user: user2.userId,
        vpsid: user2.vpsid,
        keywords: keywords,
      }
    });

    if(result.data) {
      setCurrentReedCandidate({
        name: row.Name._text,
        town: row.Town._text,
        dateSubmitted: row.other.lastCandidateLogin,
        dateAvailable: 'Unknown',
        lastModified: row.other.lastCandidateLogin,
        currentJobTitle: row.CurrentJobTitle._text,
        desiredJobTitle: row.CurrentJobTitle._text,
        // registerUrl: result.data.CV_Search_Query.Register_URL,
        partialData: result.data,
        other: row.other,
        boardId: 2,
      });
    }

    // setCurrentReedCandidate

  }

  // useEffect(() => {
  //   console.log('w00t', currentReedCandidate);
  // }, currentReedCandidate);


  const getCandidatePartial = async (id) => {
    const result = await axios({
      url: `${process.env.REACT_APP_API}/candidatePartial`,
      method: 'post',
      data: {
        id: id,
        u: cvl.username,
        s: cvl.sessionID,
        p: cvl.password,
        e: cvl.email,
        c: user2.clientName
      }
    });

    if(result.data.CV_Search_Query.Candidate_Webpage_XML_Response) {
      setCandidateData({
        name: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Name._text,
        town: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Town._cdata,
        dateSubmitted: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Date_Submitted._text,
        dateAvailable: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Date_available._text,
        lastModified: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Last_Modified._text,
        currentJobTitle: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Current_job_title._cdata,
        desiredJobTitle: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Current_job_title._cdata,
        registerUrl: result.data.CV_Search_Query.Register_URL,
        cv: utf8.decode(atob(result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Curriculum_Vitae._text))
      })
    }
  }

  const unlockCv = async (e) => {
    e && e.preventDefault();
    if(!cvl.pass.length) {
      window.open(candidateData.registerUrl._text);
      return;
    }

    setCandidateData(false);

    const result = await axios({
      url: `${process.env.REACT_APP_API}/candidateFull`,
      method: 'post',
      data: {
        id: currentCID,
        u: cvl.user,
        s: cvl.cvLibrarySession,
        p: cvl.pass,
        e: cvl.em,
        vpsid: user2.sessionID,
        user: user2.email
      }
    });

    if(result.data.CV_Search_Query.Candidate_Webpage_XML_Response) {

      if(result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate) {
        setCandidateData({
          name: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Name._cdata,
          town: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Town._cdata,
          dateSubmitted: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Date_Submitted._text,
          dateAvailable: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Date_available._text,
          lastModified: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Last_Modified._text,
          currentJobTitle: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Current_job_title._cdata,
          desiredJobTitle: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Current_job_title._cdata,
          cv: utf8.decode(atob(result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Curriculum_Vitae._text)),
          phone: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Phone._text,
          email: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Email._cdata,
          mobile: result.data.CV_Search_Query.Candidate_Webpage_XML_Response.Candidate.Mobile._text
        })
        setUnlocked(true);
      }

      const cvl = boardCfgs.find(x => 1 === parseInt(x.boardId));
      cvl.cvLibrarySession = result.data.CV_Search_Query.SessionID._text;
      const updatedCfgs = boardCfgs.filter(x => 1 !== parseInt(x.boardId));
      updatedCfgs.push(cvl);

      dispatch({
        type: 'CFGUPDATE',
        value: updatedCfgs
      })
    }
  }

  const downloadCv = async () => {
    const result = await axios({
      url: `${process.env.REACT_APP_API}/downloadCv`,
      method: 'post',
      data: {
        id: currentCID,
        u: cvl.user,
        s: cvl.cvLibrarySession,
        p: cvl.pass,
        e: cvl.em,
        vpsid: user2.sessionID,
        user: user2.email
      }
    });

    console.log(result.data);

    if(result.data.CV_Search_Query.Candidate_CV_Response) {
      const cvl = boardCfgs.find(x => 1 === parseInt(x.boardId));
      cvl.cvLibrarySession = result.data.CV_Search_Query.SessionID._text;
      const updatedCfgs = boardCfgs.filter(x => 1 !== parseInt(x.boardId));
      updatedCfgs.push(cvl);

      dispatch({
        type: 'CFGUPDATE',
        value: updatedCfgs
      })
      if(result.data.CV_Search_Query.Candidate_CV_Response.File) {
        const file = atob(result.data.CV_Search_Query.Candidate_CV_Response.File._text);
        const fileName = result.data.CV_Search_Query.Candidate_CV_Response.File._attributes.filename;
        const mime = result.data.CV_Search_Query.Candidate_CV_Response.File._attributes['content-type'];
        download(file, fileName, mime);
      }

    }
  }

  const openModal = (row) => {
    setUnlocked(false);
    setCurrentCID(row.Record_Id._text);
    boardId === 1 && getCandidatePartial(row.Record_Id._text);
    boardId === 2 && getReedPartial(row);
    boardId > 2 && setCurrentTotalCandidate(row);

    if(boardId === 99) {
      !row.boardId ? getCandidatePartial(row.Record_Id._text) : setCurrentTotalCandidate(row);
      row.boardId === 1 && getCandidatePartial(row.Record_Id._text)
      row.boardId === 2 && getReedPartial(row);
      // row.boardId === 4 && setCurrentCatererCandidate(row);
      row.boardId === 3 && setCurrentTotalCandidate(row);
      row.boardId === 4 && setCurrentTotalCandidate(row);
      row.boardId === 5 && setCurrentTotalCandidate(row);
    }
    setModalIsOpen(true);
    setAllTabCurrent(row.boardId ? row.boardId : 1);
    // setLoadingModal(true);
    // setCurrentCandidate(name);
  }

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setCandidateData(false);
    // setLoadingModal(true);
    setCurrentCID(false);
  }

  function htmlDecode(input){
    var e = document.createElement('textarea');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  data && data.sort((a, b) => {
    return parseFloat(b.Relevance._text) - parseFloat(a.Relevance._text)
  });

  return  <div>
      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Candidate">
        <button className="closeModal" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="black" size="1x" /></button>
        {
          boardId === 1 &&
            <CvLibraryModal
              candidateData={candidateData}
              unlocked={unlocked}
              downloadCv={downloadCv}
              unlockCv={unlockCv}
              shortlists={shortlists}
              addToShortlist={addToShortlist}
            />
        }

        {
          boardId === 2 && currentReedCandidate !== undefined &&
            <ReedModal
              currentReedCandidate={currentReedCandidate}
              shortlists={shortlists}
              addToShortlist={addToShortlist}
            />
        }

        {
          boardId === 3 &&
            <TotalModal
              currentTotalCandidate={currentTotalCandidate}
              shortlists={shortlists}
              addToShortlist={addToShortlist}
            />
        }
        {
          boardId === 4 &&
            <CatererModal
              currentCatererProfile={currentTotalCandidate}
              shortlists={shortlists}
              addToShortlist={addToShortlist}
            />
        }
        {
          boardId === 5 &&
            <CwModal
              currentCwProfile={currentTotalCandidate}
              shortlists={shortlists}
              addToShortlist={addToShortlist}
            />
        }
        {
          boardId === 99 &&
            allTabCurrent === 1 &&
              <CvLibraryModal
                candidateData={candidateData}
                unlocked={unlocked}
                downloadCv={downloadCv}
                unlockCv={unlockCv}
                shortlists={shortlists}
                addToShortlist={addToShortlist}
              />
        }
        {
          boardId === 99 &&
            allTabCurrent === 2 &&
              <ReedModal
                currentReedCandidate={currentReedCandidate}
                unlocked={unlocked}
                downloadCv={downloadCv}
                unlockCv={unlockCv}
                shortlists={shortlists}
                addToShortlist={addToShortlist}
              />
        }
        {
          boardId === 99 &&
            allTabCurrent === 3 &&
              <TotalModal
                currentTotalCandidate={currentTotalCandidate}
                shortlists={shortlists}
                addToShortlist={addToShortlist}
              />
        }
        {
          boardId === 99 &&
            allTabCurrent === 4 &&
              <CatererModal
                currentCatererProfile={currentTotalCandidate}
                shortlists={shortlists}
                addToShortlist={addToShortlist}
              />
        }
        {
          boardId === 99 &&
            allTabCurrent === 5 &&
              <CwModal
                currentCwProfile={currentTotalCandidate}
                shortlists={shortlists}
                addToShortlist={addToShortlist}
              />
        }
      </Modal>

  {
    data && !loading ?
      <>
    <p>Showing {data.length} of {totalRecords.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} results</p>
    <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        loader={<div className="loadMoreRecords">
                  <Loader
                   type="ThreeDots"
                   color="#02547d"
                   height={20}
                   width={40}
                   timeout={999999}
                  />
                </div>
        }
        hasMore={data.length === parseInt(totalRecords) ? false : true }
        height={400}
      >
          <div className="force-overflow candidateResults">
            <Table striped bordered hover>
              <thead>
                <tr>
                {
                  boardId === 99 && <th>Source</th>
                }
                  <th>Name</th>
                  <th>Location</th>
                  <th>Job Title</th>
                  <th>Skills</th>
                  <th>Salary Exp.</th>
                  <th>Relocate?</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                  {
                    data.length ?
                      data.map((row, i) => {
                        let img;
                        switch(parseInt(row ? row.boardId: 1)) {
                          case 1:
                           img = cvLibrary;
                          break;
                          case 2:
                            img = reed;
                          break;
                          case 3:
                            img = total;
                          break;
                          case 4:
                            img = caterer;
                          break;
                          case 5:
                            img = cw;
                          break;
                          default:
                            img = cvLibrary;
                        }
                        return <tr key={row.Name._text+i}>
                                  {

                                    boardId === 99 && <td><img src={img} width="50"/></td>
                                  }
                                  <td><span className="linkModal" onClick={(e) => openModal(row)}>{row.Name._text}</span></td>
                                  <td>{row.Town._text}</td>
                                  <td>{htmlDecode(row.CurrentJobTitle._text)}</td>
                                  <td><span className="linkModal" href="#" title={row.Skills._text}>[View]</span></td>
                                  <td>
                                  {
                                    boardId ===1 && '£'
                                  }
                                  {row.ExpectedSalary._text}</td>
                                  <td>{row.Relocate._text}</td>
                                  <td className="viewCvTd"><Button variant="primary" className="viewCv" onClick={(e) => openModal(row)}><FontAwesomeIcon icon={faIdBadge} color="white" size="1x" />&nbsp; View CV</Button></td>
                               </tr>
                      })
                      :
                      <tr>
                        <td colSpan="7">No records found</td>
                      </tr>
                  }
              </tbody>
            </Table>
          </div>
        </InfiniteScroll>
      </>
     :
     <div>
       {
         loading ?
          <div className="spinnerContainer">
            <h4>Fetching data</h4>
            <Loader
               type="ThreeDots"
               color="#02547d"
               height={70}
               width={70}
               timeout={999999} //3 secs
            />
          </div> : <p>Please make a search.</p>
       }
     </div>
  }
  </div>
}

export default CandidateResults;
