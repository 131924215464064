import React, { useState } from 'react';
import ReactShadowRoot from 'react-shadow-root';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Blob from 'blob';
import fs, { createReadStream } from 'fs';
import FileType from 'file-type';
import download from 'downloadjs';
import Loader from 'react-loader-spinner';
import {
  faUnlockAlt,
  faFileDownload,
  faPhone,
  faEnvelope,
  faMobileAlt
} from '@fortawesome/free-solid-svg-icons';
import { useStateValue } from '../../../../../state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import { Table, Button, DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';

const CvLibraryModal = ({candidateData, unlocked, downloadCv, unlockCv, currentReedCandidate, shortlists, addToShortlist}) => {
  const [loading, setLoading] = useState();
  const [cvPreview, setCvPreview] = useState(false);
  const [loadingCv, setLoadingCv] = useState(false);
  const [{ boardCfgs }] = useStateValue();
  const [candidateContactInfo, setCandidateContactInfo] = useState();
  const reed = boardCfgs.find(x => 2 === parseInt(x.boardId));

  const unlockReedCv = async () => {
    setLoading(true);

    // setCandidateContactInfo(resultContactInfo.data);

    const result = await axios({
      url: `${process.env.REACT_APP_API}/reedUnlockCv`,
      method: 'post',
      data: {
        id: currentReedCandidate.other.candidateId,
        u: reed.username,
        p: reed.password,
        // user: user2.userId,
        // vpsid: user2.vpsid,
        // keywords: keywords,
      }
    });

    setCandidateContactInfo({
      LandlinePhone: result.data.phone,
      MobilePhone: result.data.phone,
      Email: result.data.email,
    })
    setCvPreview(true);
    setLoading(false);

    console.log(result.data);
  }


  const downloadReedCv = async () => {
    setLoadingCv(true);
    const result = await axios({
      url: `${process.env.REACT_APP_API}/reedDownloadCv`,
      method: 'post',
      data: {
        id: currentReedCandidate.other.candidateId,
        u: reed.username,
        p: reed.password,
        // user: user2.userId,
        // vpsid: user2.vpsid,
        // keywords: keywords,
      }
    });

    // console.log(atob(result.data));

    const file = atob(result.data);
    const type = file.search("PDF");

    let extension, mime;
    if(type === 1) {
      extension = 'pdf';
      mime = 'application/pdf';
    } else {
      extension = 'doc';
      mime = 'application/msword';
    }
    const fileName = `candidate.${extension}`;
    download(file, fileName, mime);

  }

  return <>
  {
    1===0 ?
     <div className="spinnerContainer spinnerModal">
       <h4>Fetching CV</h4>
       <Loader
          type="ThreeDots"
          color="#02547d"
          height={100}
          width={100}
          timeout={940000}
       />
    </div>
    :
  <div className="candidateResults">
  {
    loading ?
      <div className="spinnerContainer spinnerModal">
        <h4>Fetching CV</h4>
        <Loader
           type="ThreeDots"
           color="#02547d"
           height={100}
           width={100}
           timeout={40000}
        />
     </div>
     :
     <>
     <h3>{currentReedCandidate?.name}</h3>
     <div className="modalBasicInfo">
       <Table striped bordered hover>
         <thead>
           <tr>
             <th>Location</th>
             <th>Current Job</th>
             <th>Desired Job</th>
             <th>Submitted</th>
             <th>Modified</th>
           </tr>
           <tr>

             <td>{currentReedCandidate?.town}</td>
             <td>{currentReedCandidate?.currentJobTitle}</td>
             <td>{currentReedCandidate?.desiredJobTitle}</td>
             <td>{currentReedCandidate?.desiredJobTitle}</td>
             <td>{currentReedCandidate?.lastModified}</td>
           </tr>
         </thead>
         <tbody>
           {
             candidateContactInfo &&
             <tr>
               <td colSpan="2"><FontAwesomeIcon icon={faPhone} color="black" size="1x" />: &nbsp;&nbsp;{candidateContactInfo?.LandlinePhone} &nbsp;&nbsp;&nbsp;&nbsp;</td>
               <td colSpan="2"><FontAwesomeIcon icon={faMobileAlt} color="black" size="1x" />: &nbsp;&nbsp; {candidateContactInfo?.MobilePhone} &nbsp;&nbsp;&nbsp;&nbsp;</td>
               <td colSpan="2"><FontAwesomeIcon icon={faEnvelope} color="black" size="1x" />: &nbsp;&nbsp; {candidateContactInfo?.Email}</td>
             </tr>
           }

         </tbody>
       </Table>

          <div className="catererProfile totalProfile">
            {
              <>
                <h5>Skills / CV Snippet</h5>
                {
                  currentReedCandidate?.partialData?.cvSnippets && currentReedCandidate?.partialData?.cvSnippets.map((d) => {
                    return <p>{d}</p>;
                  })
                }
                <h5>Most recent employer</h5>
                <p>{currentReedCandidate?.other?.mostRecentEmployer ? currentReedCandidate?.other?.mostRecentEmployer : 'Not provided'}</p>
              </>
            }
          </div>
     </div>
     <div className="modalFooter">
       {
         cvPreview ?
           <>
           <DropdownButton as={ButtonGroup} title="Shortlist candidate" id="bg-vertical-dropdown-4">
             {
               shortlists ? shortlists.map((sl) => {
                 return <Dropdown.Item eventKey="999" onClick={() => addToShortlist(sl.shortlistId, candidateContactInfo, currentReedCandidate)}>
                           {sl.shortlistName}
                         </Dropdown.Item>
               })
               :
               <Dropdown.Item eventKey="999" disabled={true}>
                 You have not created any shortlists yet.
               </Dropdown.Item>
             }
           </DropdownButton>
           <Button variant="success" disabled={loadingCv && true} className="downloadCvButton" onClick={() => {downloadReedCv()}}>
              {
                !loadingCv ?
                  <>
                  <FontAwesomeIcon icon={faFileDownload} color="white" size="1x" />&nbsp; Download CV
                  </>
                :
                  <Loader
                     type="ThreeDots"
                     color="#fff"
                     height={10}
                     width={100}
                     timeout={40000}
                  />
                }
                </Button>

           </>
           :
           <Button variant="primary" className="viewCv" onClick={() => {unlockReedCv()}}><FontAwesomeIcon icon={faUnlockAlt} color="white" size="1x" />&nbsp; Unlock CV</Button>
       }
     </div>
   </>
  }
  </div>
  }
</>
}

export default CvLibraryModal;
