import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavLinks from './NavLinks';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from './Sidebar';
import { useStateValue } from '../../state';
import SearchBox from './Dashboard/SearchBox';
import { saveAs } from 'file-saver';
import Tabs from '../UI/Tabs';
import Loader from 'react-loader-spinner';
import './styles.scss';
import { INDUSTRIES } from '../../constants/searchbox.js';

const MainApp = ({logout}) => {
  const [cvLibraryCandidates, setCvLibraryCandidates] = useState();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [formError, setFormError] = useState(false);
  const [allTabDataLocal, setAllTabDataLocal] = useState([]);
  const [totalRecords, setTotalRecords] = useState('');
  const [totalCatererRecords, setTotalCatererRecords] = useState('');
  const [totalReedRecords, setTotalReedRecords] = useState('');
  const [totalTotalRecords, setTotalTotalRecords] = useState('');
  const [totalCwRecords, setTotalCwRecords] = useState('');
  const [cvLibraryError, setCvLibraryError] = useState(false);
  const [catererError, setCatererError] = useState(false);
  const [reedError, setReedError] = useState(false);
  const [totalError, setTotalError] = useState(false);
  const [cwError, setCwError] = useState(false);
  const [offset, setOffset] = useState([{boardId: 1, offset: 0 },{boardId: 2, offset: 0 },{boardId: 3, offset: 0 },{boardId: 4, offset: 0 },{boardId: 5, offset: 0 }, {boardId: 99, offset: 0 }]);
  const [catererData, setCatererData] = useState();
  const [totalData, setTotalData] = useState();
  const [reedData, setReedData] = useState();
  const [cwData, setCwData] = useState();
  const [allTabExtraData, setAllTabExtraData] = useState();
  const [{ user2, boardCfgs, cvLibraryOffset, activeBoardTab, catererFull, reedFull, cvlFull, totalFull, cwFull, allTabData }, dispatch] = useStateValue();

  const retrieveSession = async () => {
    let result = await axios({
      url: `${process.env.REACT_APP_API}/fetchSession`,
      method: 'post',
      data: {
        vpsid: localStorage.getItem('vpSessionId')
      }
    });
    result.data = JSON.parse(result.data);
    const user = result.data;

    result = await axios({
      url: `${process.env.REACT_APP_API}/fetchBoardConfigs`,
      method: 'post',
      data: {
        sid: user.sessionID,
        user: user.email
      }
    });

    result.data = JSON.parse(result.data);
    const boardConfigs = result.data;

    dispatch({
      type: 'AUTHENTICATE_USER_REQUEST',
      data: user,
      boardCfgs: boardConfigs
    })
  }

  !boardCfgs && retrieveSession();

  const loadingArr = [{"id": 1, "value": loading},
                      {"id": 2, "value": loading2},
                      {"id": 3, "value": loading3},
                      {"id": 4, "value": loading4},
                      {"id": 5, "value": loading5}];

  const fetchReedResults = async (e, formData, searchButton) => {
    !reedData && setLoading2(true);
    const newOffset = offset && offset.find(x => x.boardId === activeBoardTab);
    const refreshOffset = newOffset.offset ? newOffset.offset : 0;

    if(!formData && localStorage.getItem('formData')) {
      formData = JSON.parse(localStorage.getItem('formData'));
    } else {
      setReedData([]);
    }
    const reed = boardCfgs.find(x => 2 === parseInt(x.boardId));
    let industry = INDUSTRIES.find(i => i.cvL === formData.industry);
    industry = formData.industry ? industry.reed : '';

    const result = await axios({
      url: `${process.env.REACT_APP_API}/reed`,
      method: 'post',
      data: {
        q: formData.keywords,
        t: formData.town.replace('&', '&amp;'),
        d: formData.distance,
        a: formData.date,
        o: refreshOffset,
        c: user2.clientName,
        i: industry,
        catererIndustry: formData.catererIndustry,
        type: formData.type,
        user: user2.email,
        vpsid: user2.sessionID,
        reedU: reed.user,
        reedP: reed.pass
      }
    });
    // result.data = JSON.parse(result.data);
    setTotalReedRecords(result.data.totalResults ? result.data.totalResults : 0);

    let rebuiltData = [];
    if(result.data.message) {
      setReedError(result.data.message);
      setLoading2(false);
      dispatch({
        type: 'REED_LOADING',
        value: false
      })
      return;
    }
    result.data && result.data.candidates.map((row) => {
      const rowObj = {
        Name: {
          _text: row.forename + ' ' + row.surname
        },
        Record_Id: {
          _text: row.candidateId
        },
        CurrentJobTitle: {
          _text: row.mostRecentJobTitle
        },
        Town: {
          _text: row.preferredWorkLocations.toLowerCase()
        },
        Skills: {
          _text: 'Not provided'
        },
        ExpectedSalary: {
          _text: row.minimumSalary ? row.minimumSalary : 'Unknown'
        },
        Relocate: {
          _text: 'Unknown'
        },
        other: row,
        boardId: 2,
        Relevance: {
          _text: Math.random() * (100 - 75) + 75,
        }
      }
      rebuiltData.push(rowObj);
    })

    let obj;
    if(!reedFull || searchButton) {
      obj = {
        boardId: 2,
        data: rebuiltData,
        total: result.data.totalResults
      }
    } else {
      obj = reedFull;
      obj.data = obj.data.concat(rebuiltData)
    }

    dispatch({
      type: 'REED_FULL_DATA',
      value: obj
    })

    if(!reedData || searchButton) {
      setReedData(rebuiltData);
    } else {
      const mergeData = reedData.concat(rebuiltData);
      setReedData(mergeData);
    }
    setLoading2(false);
    dispatch({
      type: 'REED_LOADING',
      value: false
    })
  }

  const fetchCatererResults = async (e, formData, searchButton) => {
    !catererData && setLoading4(true);
    const newOffset = offset && offset.find(x => x.boardId === activeBoardTab);
    const refreshOffset = newOffset.offset ? newOffset.offset : 0;

    if(!formData && localStorage.getItem('formData')) {
      formData = JSON.parse(localStorage.getItem('formData'));
    } else {
      setCatererData([]);
    }
    const caterer = boardCfgs.find(x => 4 === parseInt(x.boardId));

    const result = await axios({
      url: `${process.env.REACT_APP_API}/caterer`,
      method: 'post',
      data: {
        q: formData.keywords,
        t: formData.town.replace('&', '&amp;'),
        d: formData.distance,
        a: formData.date,
        o: refreshOffset,
        c: user2.clientName,
        i: formData.industry,
        catererIndustry: formData.catererIndustry,
        type: formData.type,
        user: user2.email,
        vpsid: user2.sessionID,
        catU: caterer.user,
        catP: caterer.pass
      }
    });
    result.data = JSON.parse(result.data);
    setTotalCatererRecords(result.data.TotalResultsCount ? result.data.TotalResultsCount : 0)

    let rebuiltData = [];
    if(result.data.Message) {
      setCatererError(result.data.Message);
      setLoading4(false);
      dispatch({
        type: 'CATERER_LOADING',
        value: false
      })
      return;
    }
    result.data && result.data.Candidates.map((row) => {
      const rowObj = {
        Name: {
          _text: row.ForeName + ' ' + row.Surname
        },
        Record_Id: {
          _text: row.Id
        },
        CurrentJobTitle: {
          _text: row.CurrentJobTitle
        },
        Town: {
          _text: row.CurrentLocation
        },
        Skills: {
          _text: row.KeySkills
        },
        ExpectedSalary: {
          _text: row.DesiredSalary ? `£${row.DesiredSalary.LowerValue} - ${row.DesiredSalary.UpperValue}` : 'Unknown'
        },
        Relocate: {
          _text: row.DesiredLocations[0] === "Willing to relocate" ? 'Yes' : 'No'
        },
        other: row,
        boardId: 4,
        Relevance: {
          _text: row.Relevancy
        }
      }
      rebuiltData.push(rowObj);
    })

    let obj;
    if(!catererFull || searchButton) {

      obj = {
        boardId: 4,
        data: rebuiltData,
        total: result.data.TotalResultsCount
      }
    } else {
      obj = catererFull;
      obj.data = obj.data.concat(rebuiltData)
    }

    dispatch({
      type: 'CATERER_FULL_DATA',
      value: obj
    })

    if(!catererData || searchButton) {
      setCatererData(rebuiltData);
    } else {
      const mergeData = catererData.concat(rebuiltData);
      setCatererData(mergeData);
    }
    setLoading4(false);
    dispatch({
      type: 'CATERER_LOADING',
      value: false
    })
  }


  const fetchCwResults = async (e, formData, searchButton) => {
    !cwData && setLoading5(true);

    const newOffset = offset && offset.find(x => x.boardId === activeBoardTab);
    const refreshOffset = newOffset.offset ? newOffset.offset : 0;

    if(!formData && localStorage.getItem('formData')) {
      formData = JSON.parse(localStorage.getItem('formData'));
    } else {
      setCwData([]);
    }
    const cw = boardCfgs.find(x => 5 === parseInt(x.boardId));

    const result = await axios({
      url: `${process.env.REACT_APP_API}/cw`,
      method: 'post',
      data: {
        q: formData.keywords,
        t: formData.town.replace('&', '&amp;'),
        d: formData.distance,
        a: formData.date,
        o: refreshOffset,
        c: user2.clientName,
        i: formData.industry,
        catererIndustry: formData.catererIndustry,
        type: formData.type,
        user: user2.email,
        vpsid: user2.sessionID,
        cwU: cw.user,
        cwP: cw.pass
      }
    });
    result.data = JSON.parse(result.data);
    setTotalCwRecords(result.data.TotalResultsCount ? result.data.TotalResultsCount : 0)

    let rebuiltData = [];
    if(result.data.Message) {
      setCwError(result.data.Message);
      dispatch({
        type: 'CW_LOADING',
        value: false
      })
      setLoading5(false);
      return;
    }
    result.data && result.data.Candidates.map((row) => {
      const rowObj = {
        Name: {
          _text: row.ForeName + ' ' + row.Surname
        },
        Record_Id: {
          _text: row.Id
        },
        CurrentJobTitle: {
          _text: row.CurrentJobTitle
        },
        Town: {
          _text: row.CurrentLocation
        },
        Skills: {
          _text: row.KeySkills
        },
        ExpectedSalary: {
          _text: row.DesiredSalary ? `£${row.DesiredSalary.LowerValue} - ${row.DesiredSalary.UpperValue}` : 'Unknown'
        },
        Relocate: {
          _text: row.DesiredLocations[0] === "Willing to relocate" ? 'Yes' : 'No'
        },
        other: row,
        boardId: 5,
        Relevance: {
          _text: row.Relevancy
        }
      }
      rebuiltData.push(rowObj);
    })

    let obj;
    if(!cwFull || searchButton) {
      obj = {
        boardId: 5,
        data: rebuiltData,
        total: result.data.TotalResultsCount
      }
    } else {
      obj = cwFull;
      obj.data = obj.data.concat(rebuiltData)
    }

    dispatch({
      type: 'CW_FULL_DATA',
      value: obj
    })

    if(!cwData || searchButton) {
      setCwData(rebuiltData);
    } else {
      const mergeData = cwData.concat(rebuiltData);
      setCwData(mergeData);
    }
    setLoading5(false);
    dispatch({
      type: 'CW_LOADING',
      value: false
    })
  }

  const fetchTotalResults = async (e, formData, offsetTotal, searchButton) => {

    !totalData && setLoading3(true);

    if(!offsetTotal) {
      offsetTotal = 0;
    }

    const newOffset = offset && offset.find(x => x.boardId === activeBoardTab);
    const refreshOffset = newOffset.offset ? newOffset.offset : 0;

    if(!formData && localStorage.getItem('formData')) {
      formData = JSON.parse(localStorage.getItem('formData'));
      // formData.offset = offset;
    } else {
      setTotalData([]);
    }
    const total = boardCfgs.find(x => 3 === parseInt(x.boardId));
    let industry = INDUSTRIES.find(i => i.cvL === formData.industry);
    industry = formData.industry ? industry.total : '';

    const result = await axios({
      url: `${process.env.REACT_APP_API}/total`,
      method: 'post',
      data: {
        q: formData.keywords,
        t: formData.town.replace('&', '&amp;'),
        d: formData.distance,
        a: formData.date,
        o: refreshOffset,
        c: user2.clientName,
        i: industry,
        type: formData.type,
        user: user2.email,
        vpsid: user2.sessionID,
        totU: total.user,
        totP: total.pass
      }
    });
    result.data = JSON.parse(result.data);
    setTotalTotalRecords(result.data.TotalResultsCount ? result.data.TotalResultsCount : 0)

    let rebuiltData = [];
    if(result.data.Message) {
      setTotalError(result.data.Message);
      dispatch({
        type: 'TOTAL_LOADING',
        value: false
      })
      setLoading3(false);
      return;
    }

    result.data && result.data.Candidates.map((row) => {
      const rowObj = {
        Name: {
          _text: row.ForeName + ' ' + row.Surname
        },
        Record_Id: {
          _text: row.Id
        },
        CurrentJobTitle: {
          _text: row.CurrentJobTitle
        },
        Town: {
          _text: row.CurrentLocation
        },
        Skills: {
          _text: row.KeySkills
        },
        ExpectedSalary: {
          _text: row.DesiredSalary ? `£${row.DesiredSalary.LowerValue} - ${row.DesiredSalary.UpperValue}` : 'Unknown'
        },
        Relocate: {
          _text: row.DesiredLocations[0] === "Willing to relocate" ? 'Yes' : 'No'
        },
        other: row,
        boardId: 3,
        Relevance: {
          _text: row.Relevancy
        }
      }
      rebuiltData.push(rowObj);
    })

    let obj;
    if(!totalFull || searchButton) {
      obj = {
        boardId: 3,
        data: rebuiltData,
        total: result.data.TotalResultsCount
      }
    } else {
      obj = totalFull;
      obj.data = obj.data.concat(rebuiltData)
    }

    dispatch({
      type: 'TOTAL_FULL_DATA',
      value: obj
    })

    if(!totalData || searchButton) {
      setTotalData(rebuiltData);
    } else {
      const mergeData = totalData.concat(rebuiltData);
      setTotalData(mergeData);
    }

    setLoading3(false);
    dispatch({
      type: 'TOTAL_LOADING',
      value: false
    })
  }

  const handleSubmit = async (e, formData, offset, searchButton) => {
    e && e.preventDefault();
    if(formData && (!formData.keywords.length || !formData.town.length)) {
      setFormError(true);
      setLoading(false);
      return;
    }

    if(searchButton) {
      resetSearch();
      setReedError(false);
      setOffset([
        {boardId: 1, offset: 0 },
        {boardId: 2, offset: 0 },
        {boardId: 3, offset: 0 },
        {boardId: 4, offset: 0 },
        {boardId: 5, offset: 0 },
        {boardId: 99, offset: 0 }
      ]);

      dispatch({
        type: 'CATERER_FULL_DATA',
        value: false
      },
      {
        type: 'CVL_FULL_DATA',
        value: false
      },
      {
        type: 'REED_FULL_DATA',
        value: false
      },
      {
        type: 'TOTAL_FULL_DATA',
        value: false
      },
      {
        type: 'CW_FULL_DATA',
        value: false
      });
    }

    formData && localStorage.setItem('formData', JSON.stringify(formData));

    const cvl = boardCfgs.find(x => 1 === parseInt(x.boardId) & parseInt(x.active) === 1);
    const reed = boardCfgs.find(x => 2 === parseInt(x.boardId) & parseInt(x.active) === 1);
    const total = boardCfgs.find(x => 3 === parseInt(x.boardId) & parseInt(x.active) === 1);
    const caterer = boardCfgs.find(x => 4 === parseInt(x.boardId) & parseInt(x.active) === 1);
    const cw = boardCfgs.find(x => 5 === parseInt(x.boardId) & parseInt(x.active) === 1);

    // HANDLE CATERER
    if(searchButton && caterer) {
      setCatererData([]);
      fetchCatererResults(e, formData, searchButton);
      setLoading4(true);
      dispatch({
        type: 'CATERER_LOADING',
        value: true
      },
      {
        type: 'CATERER_FULL_DATA',
        value: false
      })
    }

    // HANDLE REED
    if(searchButton && reed) {
      setReedData([]);
      fetchReedResults(e, formData, searchButton);
      setLoading2(true);
      dispatch({
        type: 'REED_LOADING',
        value: true
      },
      {
        type: 'REED_FULL_DATA',
        value: false
      })
    }

    if(!searchButton) {
      totalFull && (total && totalFull.data.length < totalFull.total && (activeBoardTab === 3 || activeBoardTab === 99)) && fetchTotalResults(e, formData, offset.offset);
      catererFull && (caterer && catererFull.data.length < catererFull.total && (activeBoardTab === 4 || activeBoardTab === 99)) && fetchCatererResults(e, formData, offset.offset);
      cwFull && (cw && cwFull.data.length < cwFull.total && (activeBoardTab === 5 || activeBoardTab === 99)) && fetchReedResults(e, formData, offset.offset);
      reedFull && (reed && reedFull.data.length < reedFull.total && (activeBoardTab === 2 || activeBoardTab === 99)) && fetchReedResults(e, formData, offset.offset);
    }

    // HANDLE TOTAL
    if(searchButton && total) {
      setTotalData([]);
      fetchTotalResults(e, formData, offset.offset, searchButton);
      setLoading3(true);
      dispatch({
        type: 'TOTAL_LOADING',
        value: true
      })
    }

    // HANDLE CWJOBS
    if(searchButton && cw) {
      setCwData([]);
      fetchCwResults(e, formData, searchButton);
      setLoading5(true);
      dispatch({
        type: 'CW_LOADING',
        value: true
      })
    }

    // if((activeBoardTab !== 1 && activeBoardTab !== 99) || !cvl) {
    //   return;
    // }

    // HANDLE CV-LIBRARY

    const newOffset = offset && offset.find(x => x.boardId === activeBoardTab);
    const refreshOffset = newOffset.offset ? newOffset.offset : 0;

    if(!formData && localStorage.getItem('formData')) {
      formData = JSON.parse(localStorage.getItem('formData'));
      formData.offset = offset;
    } else {
      setCvLibraryCandidates([]);
      setCatererData([]);
      setLoading(true);
      dispatch({
        type: 'CVL_LOADING',
        value: true
      })
      setFormError(false);
    }

    if(formData && (!formData.keywords.length || !formData.town.length)) {
      setFormError(true);
      setLoading(false);
      dispatch({
        type: 'CVL_LOADING',
        value: false
      })
      setCvLibraryCandidates(false);
      setCatererData(false);
    } else {
      const cvL = boardCfgs.find(x => 1 === parseInt(x.boardId));
      const result = await axios({
        url: `${process.env.REACT_APP_API}/searchCandidates`,
        method: 'post',
        data: {
          q: formData.keywords,
          t: formData.town.replace('&', '&amp;'),
          d: formData.distance,
          a: formData.date,
          s: cvL.cvLibrarySession,
          u: cvL.user,
          e: cvL.em,
          p: cvL.pass,
          c: user2.clientName,
          i: formData.industry,
          o: refreshOffset,
          type: formData.type,
          user: user2.email,
          vpsid: user2.sessionID
        }
      });

      if(result.data.response.error) {
        setCvLibraryError(result.data.response.reason);
        dispatch({
          type: 'CVL_LOADING',
          value: false
        })
      } else {
        localStorage.setItem('sessionID', result.data.response.sessionID);

        const cvl = boardCfgs.find(x => 1 === parseInt(x.boardId));
        cvl.cvLibrarySession = result.data.response.sessionID;

        const updatedCfgs = boardCfgs.filter(x => 1 !== parseInt(x.boardId));
        updatedCfgs.push(cvl);
        dispatch({
          type: 'CFGUPDATE',
          value: updatedCfgs
        })

        setCvLibraryError(false);
        setLoading(false);
        dispatch({
          type: 'CVL_LOADING',
          value: false
        })

        result.data.response.data.Result.forEach(row => {
          row.Relevance._text = parseInt(row.Relevance._text.replace(/\D/g,''));
        });

        let obj;
        if(!cvlFull || searchButton) {
          obj = {
            boardId: 1,
            data: result.data.response.data.Result,
            total: parseInt(result.data.response.total._text)
          }
        } else {
          obj = cvlFull;
          obj.data = obj.data.concat(result.data.response.data.Result)
        }
        dispatch({
          type: 'CVL_FULL_DATA',
          value: obj
        })

        setCvLibraryCandidates(refreshOffset>1 ? cvLibraryCandidates.concat(result.data.response.data.Result) : result.data.response.data.Result);
        setTotalRecords(result.data.response.total._text);
      }
    }
  }

  useEffect(() => {
      const forceUpdate = offset.find(x => x.offset>1);
      forceUpdate && handleSubmit(false, false, offset);
  }, [offset]);


  const resetSearch = () => {
    setOffset([{boardId: 1, offset: 0 },{boardId: 2, offset: 0 }, {boardId: 3, offset: 0 },{boardId: 4, offset: 0 },{boardId: 4, offset: 0 },{boardId: 99, offset: 0 }])
    localStorage.removeItem('formData');
    setFormError(false);
    setCvLibraryCandidates(false);
    setCatererData(false);
    setCwData(false);
    setTotalData(false);
    setLoading4(false);
    setLoading(false);
    setReedData(false);
    setCvLibraryError(false);

    if(boardCfgs) {
      const active = boardCfgs.filter(cfgs => cfgs.active === "1" );

      dispatch({
        type: 'UPDATE_ACTIVE_BOARD_TAB',
        tab: parseInt(active[0].boardId),
      });
    }

    dispatch({
      type: 'CVL_LOADING',
      value: false
    },
    {
      type: 'CATERER_LOADING',
      value: false
    },
    {
      type: 'TOTAL_LOADING',
      value: false
    },
    {
      type: 'REED_LOADING',
      value: false
    },
    {
      type: 'CW_LOADING',
      value: false
    },
    {
      type: 'CATERER_LOADING',
      value: false
    },
    {
      type: 'ALL_LOADING',
      value: false
    },
    {
      type: 'SET_ACTIVE_TAB',
      value: 1
    },
    {
      type: 'CATERER_FULL_DATA',
      value: false
    },
    {
      type: 'CVL_FULL_DATA',
      value: false
    },
    {
      type: 'REED_FULL_DATA',
      value: false
    },
    {
      type: 'TOTAL_FULL_DATA',
      value: false
    },
    {
      type: 'CW_FULL_DATA',
      value: false
    });
  }

  const activeBoards = boardCfgs && boardCfgs.filter(x => 1 === parseInt(x.active));

  return (
    <div className="appContainer">
      <div className="gradient">
        <Sidebar />
        <NavLinks logout={logout}/>
        {
          boardCfgs && boardCfgs.length ?
           activeBoards ?
            <>
              <SearchBox handleSubmit={handleSubmit} resetSearch={resetSearch} formError={formError} />
              <Tabs
                handleSubmit={handleSubmit}
                data={cvLibraryCandidates}
                catererData={catererData}
                reedData={reedData}
                totalData={totalData}
                cwData={cwData}
                loading={loadingArr}
                totalRecords={totalRecords}
                totalReedRecords={totalReedRecords}
                totalCatererRecords={totalCatererRecords}
                totalTotalRecords={totalTotalRecords}
                totalCwRecords={totalCwRecords}
                cvLibraryError={cvLibraryError}
                catererError={catererError}
                reedError={reedError}
                cwError={cwError}
                totalError={totalError}
                offset={offset}
                setOffset={setOffset}
                allTabExtraData={allTabExtraData}
                />
            </>
            :
            <div className="searchFormContainer">
              <div className="noActiveBoards">
                <h3><span><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} size="1x" /></span> You have no active job boards. Please configure and activate a job board in <span className="settingsLink"><NavLink to="/settings">settings page</NavLink></span> to start using this app.</h3>
              </div>
            </div>
          :
            <div className="dashboardLoader">
              <Loader
                 type="ThreeDots"
                 color="#fff"
                 height={50}
                 width={250}
                 timeout={912000}
              />
            </div>
        }
      </div>
    </div>
  )
}

export default MainApp;
