import React, { useState, useEffect } from 'react';
import NavLinks from '../NavLinks';
import axios from 'axios';
import Sidebar from '../Sidebar';
import { Table, Button, Form, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from "react-csv";
import Modal from 'react-modal';
import { useStateValue } from '../../../state';
import { ToastContainer, toast } from 'react-toastify';
import {
  faTimesCircle,
  faTimes
 } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const Shortlists = ({logout}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [shortlists, setShortlists] = useState();
  const [newShortlistName, setNewShortlistName] = useState();
  const [currentShortlist, setCurrentShortlist] = useState();
  const [currentShortlistName, setCurrentShortlistName] = useState();
  const [currentShortlistId, setCurrentShortlistId] = useState();
  const [currentId, setCurrentId] = useState();
  const [addShortlistError, setAddShortlistError] = useState(false);
  const [{ user2 }] = useStateValue();

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      minHeight            : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  const confirmDeleteShortlist = async () => {
    await axios({
      url: `${process.env.REACT_APP_API}/deleteShortlist`,
      method: 'post',
      data: {
        id: user2.userId,
        sid: user2.sessionID,
        itemId: currentShortlistId
      }
    });

    setShortlists(shortlists.filter(x => x.shortlistId !== currentShortlistId));
    closeModal();
  }

  const openModal = (type, id) => {
    setModalIsOpen(true);
    setModalType(type);
    setCurrentId(id);
  }

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const confirmRemoveCandidate = async () => {
    await axios({
      url: `${process.env.REACT_APP_API}/deleteFromShortlist`,
      method: 'post',
      data: {
        id: user2.userId,
        sid: user2.sessionID,
        itemId: currentId
      }
    });

    setCurrentShortlist(currentShortlist.filter(x => x.shortlistItems !== currentId));

    closeModal();
  }

  useEffect(() => {
    const fetchShortlists = async (email, sessionID) => {
      const result = await axios({
        url: `${process.env.REACT_APP_API}/fetchShortlists`,
        method: 'post',
        data: {
          id: user2.userId,
          sid: user2.sessionID
        }
      });
      setShortlists(JSON.parse(result.data));
    }

    fetchShortlists();
  }, [])

  const loadShortlist = async (shortlist) => {
    const result = await axios({
      url: `${process.env.REACT_APP_API}/fetchShortlist`,
      method: 'post',
      data: {
        id: user2.userId,
        sid: user2.sessionID,
        shortlistId: shortlist.shortlistId
      }
    });
    setCurrentShortlist(JSON.parse(result.data));
    setCurrentShortlistName(shortlist.shortlistName);
    setCurrentShortlistId(shortlist.shortlistId);
  }

  const saveNewShortlist = async () => {
    if(newShortlistName === undefined) {
      setAddShortlistError('Please provide a name for your shortlist')
    }
    else {
      const result = await axios({
        url: `${process.env.REACT_APP_API}/addNewShortlist`,
        method: 'post',
        data: {
          id: user2.userId,
          sid: user2.sessionID,
          name: newShortlistName
        }
      });

      const newRecord = JSON.parse(result.data);
      shortlists.unshift(newRecord);
      closeModal();
      toast('Shortlist succesfully Added');
    }
  }

  const headers = [
  { label: "Name", key: "firstName" },
  { label: "Email", key: "email" },
  { label: "Location", key: "location" },
  { label: "Mobile", key: "mobile" },
  { label: "Phone", key: "phone" },
  { label: "Email", key: "email" },
  { label: "Last Modified", key: "cvLastModified" },

];

  return <div className="appContainer">
          <ToastContainer />
          <div className="gradient">
            <Sidebar user={user2} />
            <NavLinks user={user2} logout={logout}/>
              <div className="tabsWrapper">
                <div className="tabsContainer verticalTabsContainer">
                  <div className="sideTab shortlistSide">
                    <Button variant="primary" className="newShortlist" type="submit" onClick={() => openModal('newShortlist')}>
                     <FontAwesomeIcon icon={['far', 'plus-square']} size="lg" /> &nbsp;
                      Create shortlist
                    </Button>
                    <h3>My Shortlists</h3>
                    {
                      shortlists && shortlists.length ?
                        <ul className="shortlistItems">
                          {
                            shortlists && shortlists.map((shortlist) => {
                              return <li key={shortlist.shortlistId}><span onClick={() => loadShortlist(shortlist)}className="linkModal">{shortlist.shortlistName}</span></li>
                            })
                          }
                        </ul>
                        :
                        <p className="noShortlists">You've not created any shortlists yet!</p>
                    }
                  </div>
                  <div className="verticalTabsContent">
                  {
                    currentShortlist ?
                    <>
                      <div className="shortlistTitle">
                        <h1 className="shortlistTitle">{currentShortlistName}</h1>
                        <div className="shortlistButtons">
                        <CSVLink data={currentShortlist ? currentShortlist : []} headers={headers}>
                          <Button variant="success" className="floatRight" type="submit">
                           <FontAwesomeIcon icon={['far', 'download']} size="1x" /> &nbsp;
                             <span>Export shortlist</span>
                          </Button>
                        </CSVLink>

                          <Button variant="danger" className="floatRight" type="submit" onClick={() => openModal('deleteShortlist')}>
                           <FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" /> &nbsp;
                             <span>Delete shortlist</span>
                          </Button>
                        </div>
                      </div>
                      <div className="scrollbar scrollbar2" id="style-5">
                        <div className="force-overflow">
                          <Table striped bordered hover className="candidateShortlist">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Location</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th>Modified</th>
                                <th>Board</th>
                                <th>View</th>
                                <th>Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                          {
                            currentShortlist && currentShortlist.length ?
                              currentShortlist.map((s) => {
                                let boardName;
                                switch(parseInt(s.boardId)) {
                                  case 1:
                                    boardName = 'CV-Library'
                                    break;
                                  case 2:
                                    boardName = 'Reed'
                                    break;
                                  case 3:
                                    boardName = 'Total'
                                    break;
                                  case 4:
                                    boardName = 'Caterer'
                                    break;
                                  default:
                                    //TBC
                                }
                                return <tr key={s.firstName + ' ' + s.lastName}>
                                          <td>{s.firstName + ' ' + s.lastName}</td>
                                          <td>{s.location}</td>
                                          <td>{s.mobile}</td>
                                          <td>{s.email}</td>
                                          <td>{s.cvLastModified}</td>
                                          <td>{boardName}</td>
                                          <td>
                                            <Button title="Coming soon" variant="primary" className="activateBoard fixedDimButton blueButton noMarginRight" disabled={true} type="submit" onClick={() => { openModal('viewCv')}}>
                                             <FontAwesomeIcon icon={['fal', 'file-user']} size="lg" /> &nbsp;
                                               View CV
                                            </Button>
                                          </td>
                                          <td>

                                            <Button variant="primary" className="disabledBoard fixedDimButton removeButton noMarginRight" type="submit" onClick={() => { openModal('deleteFromShortlist', s.shortlistItems)}}>
                                             <FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" /> &nbsp;
                                               Remove
                                            </Button>
                                          </td>
                                        </tr>

                              })
                             :
                             <tr>
                               <td colSpan="8">
                                  You have not added anyone to this list yet
                              </td>
                             </tr>
                          }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </>
                  :
                  <p>Please select a shortlist</p>

                  }
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Candidate">
            <button className="closeModal" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="black" size="1x" /></button>
            {
              modalType === 'newShortlist' &&
              <>
                <h1>Create Shortlist</h1>
                <p>Please provide the name of your new shortlist:</p>
                {
                  addShortlistError &&
                    <Alert variant="danger">
                      Please provide a keyword and a location
                    </Alert>
                }
                <Form.Row>
                  <Form.Group as={Col} controlId="clientName" md="6">
                    <Form.Control type="text" placeholder="New shortlist name" value={newShortlistName} onChange={(e) => { setNewShortlistName(e.target.value)}} />
                  </Form.Group>
                </Form.Row>
              <div className="modalFooter">
                <Button variant="success" className="activateBoard" onClick={() => {saveNewShortlist()} }><FontAwesomeIcon icon={['far', 'save']} size="1x" />&nbsp; Save</Button>
                <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
              </div>
            </>
          }
          {
            modalType === 'deleteFromShortlist' &&
            <>
            <h1>Remove Candidate</h1>
            <p>Are you sure you want to remove this candidate from this shortlist?</p>
          <div className="modalFooter">
            <Button variant="success" className="activateBoard" onClick={() => {confirmRemoveCandidate()} }><FontAwesomeIcon icon={['far', 'save']} size="1x" />&nbsp; Save</Button>
            <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
          </div>
            </>
          }

          {
            modalType === 'deleteShortlist' &&
            <>
            <h1>Delete {currentShortlistName}?</h1>
            <p>Are you sure you want to delete this shortlist?</p>
          <div className="modalFooter">
            <Button variant="success" className="activateBoard" onClick={() => {confirmDeleteShortlist()} }><FontAwesomeIcon icon={['far', 'save']} size="1x" />&nbsp; Yes</Button>
            <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; No</Button>
          </div>
            </>
          }


            </Modal>
          </div>
}

export default Shortlists;
