import React, { useState } from 'react';
import { Form, Col, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  faTimes,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

const AddClient = ({closeModal, refresh, user}) => {
  // const [client, setClient] = useState();
  const [clientName, setClientName] = useState();
  const [userLimit, setUserLimit] = useState(5);
  const [error, setError] = useState();

  const addClientRequest = async () => {
    if(!clientName || !userLimit) {
      setError('Please complete all fields');
      return;
    }

    const result = await axios({
      url: `${process.env.REACT_APP_API}/addClient`,
      method: 'post',
      data: {
        clientName,
        userLimit,
        sid: user.sessionID,
        user: user.email
      }
    });

    if(result.data.response) {
      setError(result.data.response);
    } else {
      toast("Client successfully added");
      closeModal();
      refresh();
    }
  }

  return (
    <>
      <h1>Add Client</h1>
        {
          error &&
          <Alert variant="danger">
            <strong>Error:</strong> {error}
          </Alert>
        }
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="clientName" md="6">
              <Form.Label>Client Name</Form.Label>
              <Form.Control type="text" placeholder="Client Name" value={clientName} onChange={(e) => { setClientName(e.target.value)}} />
            </Form.Group>

            <Form.Group as={Col} controlId="ownerPassword" md="2">
              <Form.Label>Max users</Form.Label>
              <Form.Control type="text" value={userLimit} onChange={(e) => { setUserLimit(e.target.value)}} />
            </Form.Group>
          </Form.Row>
        </Form>
    <div className="modalFooter">
        <Button variant="success" className="activateBoard" onClick={() => { addClientRequest()} }><FontAwesomeIcon icon={faPlus} color="white" size="1x" />&nbsp; Add</Button>
        <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
      </div>
    </>
  )
}

export default AddClient;
