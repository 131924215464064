import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useStateValue } from '../../../../../state';
import axios from 'axios';
import { toast } from 'react-toastify';

const ChangePassword = ({closeModal}) => {
  const [error, setError] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [repeatNewPassword, setRepeatNewPassword] = useState();
  const [canSee1, setCanSee1] = useState(false);
  const [canSee2, setCanSee2] = useState(false);
  const [canSee3, setCanSee3] = useState(false);
  const [{ user2 }] = useStateValue();

  const canSee = (e, id) => {
    e.preventDefault();
    id === 1 && setCanSee1(!canSee1);
    id === 2 && setCanSee2(!canSee2);
    id === 3 && setCanSee3(!canSee3);
  }

  const confirmEdit = async () => {
    const validate = validateForm();
    if(validate) {
      const result = await axios({
        url: `${process.env.REACT_APP_API}/changePassword`,
        method: 'post',
        data: {
          sid: user2.sessionID,
          user: user2.email,
          oldPassword,
          newPassword
        }
      });
      result.data = JSON.parse(result.data);
      result.data.status === 'ERR' && setError(result.data.response);
      result.data.status === 'OK' && closeModal();
    }
    toast("Password updated");
  }

  const validateForm = () => {
    if (!newPassword || !oldPassword || !repeatNewPassword) {
       setError('Please complete all fields');
       return false;
    }
    if (newPassword !== repeatNewPassword) {
      setError('Passwords do not match');
      return false;
    }
    if (newPassword === oldPassword) {
       setError('Old password is the same as new password');
       return false;
    }
    return true;
  }

  return (<Form>
    <h1>Change Password</h1>
    {
      error &&
      <Alert variant="danger">
        <strong>Error:</strong> {error}
      </Alert>
    }
    <Form.Group controlId="oldPassword">
      <Form.Label>Old password</Form.Label>
      <Form.Control type={canSee1 ? 'text' : 'password'} placeholder="Old password" value={oldPassword} onChange={(e) => { setOldPassword(e.target.value) }} />
      <span className="eye" onClick={(e) => canSee(e, 1)}><FontAwesomeIcon icon={['far', canSee1 ? 'eye' : 'eye-slash']} size="lg" /></span>
    </Form.Group>
    <Form.Group controlId="newPassword">
      <Form.Label>New password</Form.Label>
      <Form.Control type={canSee2 ? 'text' : 'password'} placeholder="New password" value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} />
      <span className="eye" onClick={(e) => canSee(e, 2)}><FontAwesomeIcon icon={['far', canSee2 ? 'eye' : 'eye-slash']} size="lg" /></span>
    </Form.Group>
    <Form.Group controlId="repeatNewPassword">
      <Form.Label>Repeat new password</Form.Label>
      <Form.Control type={canSee3 ? 'text' : 'password'} placeholder="Confirm new password" value={repeatNewPassword} onChange={(e) => { setRepeatNewPassword(e.target.value) }} />
      <span className="eye" onClick={(e) => canSee(e, 3)}><FontAwesomeIcon icon={['far', canSee3 ? 'eye' : 'eye-slash']} size="lg" /></span>
    </Form.Group>
    <div className="modalFooter">
      <Button variant="success" className="activateBoard" onClick={() => { confirmEdit()} }><FontAwesomeIcon icon={faCheck} color="white" size="1x" />&nbsp; Save</Button>
      <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
    </div>
  </Form>)
}

export default ChangePassword;
