import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cvLibrary from '../../../../images/cv-library.png';
import reed from '../../../../images/reed.png';
import total from '../../../../images/total.png';
import caterer from '../../../../images/caterer.png';
import cw from '../../../../images/cw.png';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { useStateValue } from '../../../../state';
import { NavLink } from 'react-router-dom';
import {
  faTimes,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import './styles.scss';

const JobBoard = ({image}) => {
  const [selectedBoard, setSelectedBoard] = useState();
  const [modalType, setModalType] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [{ boardCfgs }, dispatch] = useStateValue();

  const confirmToggle = async (selectedBoard) => {
    const updatedConfigs = [];
    boardCfgs.forEach((board) => {
      if(parseInt(board.boardId) === parseInt(selectedBoard.boardId)) {
        board.active = parseInt(board.active) === 0 ? 1 : 0;
      }
      updatedConfigs.push(board);
    })

    await axios({
      url: `${process.env.REACT_APP_API}/toggleBoard`,
      method: 'post',
      data: {
        id: selectedBoard.configId,
        toggle: selectedBoard.active
      }
    });

    dispatch({
      type: 'UPDATE_BOARD_CONFIGS',
      data: updatedConfigs,
    })

    closeModal();
  }

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      minHeight            : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  const toggleBoard = (e, board) => {
    e.preventDefault();
    setSelectedBoard(board);
    setModalIsOpen(true);
    setModalType(parseInt(board.active) === 1 ? 'disable' : 'enable');
    // board.checked = !board.checked;
  }

  const confirm = (selectedBoard) => {
    confirmToggle(selectedBoard);
    closeModal();
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  const openModal = () => {

  }

  // const disabled = !boardConfigs.active && true;
  const disabled = false;

  return (
    <div>
      {
        boardCfgs ?
          boardCfgs.length ?
            boardCfgs.map((board) => {
              let img;
              switch(parseInt(board.boardId)) {
                case 1:
                 img = cvLibrary;
                break;
                case 2:
                  img = reed;
                break;
                case 3:
                  img = total;
                break;
                case 4:
                  img = caterer;
                break;
                case 5:
                  img = cw;
                break;
                default:
                  img = cvLibrary;
              }

              return <div key={board.boardName} className={ disabled ? "disabled sidebarJobBoard" : "sidebarJobBoard"}><img className={ parseInt(board.boardId)===3 || parseInt(board.boardId)===4 || parseInt(board.boardId)===5 ? 'total' : ''} alt={board.boardName} src={img} width="90" />
                <label className="switch">
                  <input type="checkbox" checked={parseInt(board.active) === 1 ? true : false } onChange={ (e) => { toggleBoard(e, board) }}/>
                  <span title="Toggle board on/off" className={ disabled ? 'slider round disabled' : 'slider round' } ></span>
                </label>
              </div>
            })
          :
          <div className="noBoards">
            <p><span><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} size="md" /></span> You have no active job boards. Please configure and activate a job board in <span className="settingsLink"><NavLink to="/settings">settings page</NavLink></span> to start using this app.</p>
          </div>
        :
        <div className="sidebarSpinner">
          <Loader
             type="ThreeDots"
             color="#02547d"
             height={20}
             width={100}
             timeout={912000}
          />
        </div>
      }
      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Candidate">
        <button className="closeModal" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="black" size="1x" /></button>
      {
        modalType === 'disable' &&
          <>
            <h1>Disable {selectedBoard.name}</h1>
            <p>Are you sure you want to disable this job board? It will will no longer be available on your search results.</p>
            <div className="modalFooter">
              <Button variant="success" className="activateBoard" onClick={() => { confirm(selectedBoard) }}><FontAwesomeIcon icon={faCheck} color="white" size="1x" />&nbsp; Confirm</Button>
              <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
            </div>
          </>
      }
      {
        modalType === 'enable' &&
          <>
            <h1>Enable {selectedBoard.name}</h1>
            <p>Enable this job board?</p>
            <div className="modalFooter">
              <Button variant="success" className="activateBoard" onClick={() => { confirm(selectedBoard) }}><FontAwesomeIcon icon={faCheck} color="white" size="1x" />&nbsp; Confirm</Button>
              <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
            </div>
          </>
      }
    </Modal>
    </div>
  )
}

export default JobBoard;
