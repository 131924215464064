import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import AddUser from './AddUser';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import { ToastContainer, toast } from 'react-toastify';
import {
  faTimesCircle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import './styles.scss';

const SettingsUsers = ({user}) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserId, setSelectedUserId] = useState();
  const [users, setUsers] = useState();
  const [editUser, setEditUser] = useState();
  const [clientsList, setClientsList] = useState();

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      minHeight            : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  const openModal = (type, record) => {
    setModalIsOpen(true);
    setModalType(type);

    if (type === 'editUser') {
      setEditUser(record);
    }

    if(type === 'deleteUser') {
      setSelectedUser(record.firstName + ' ' + record.lastName);
      setSelectedUserId(record.userId);
    }

  }

  const refresh = () => {
    closeModal();
    fetchUsers();
  }

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }


  const closeModal = (refresh) => {
    refresh && fetchUsers();
    setModalIsOpen(false);
  }

  const fetchUsers = async () => {
    const result = await axios({
      url: `${process.env.REACT_APP_API}/fetchUsers`,
      method: 'post',
      data: {
        user: user.email,
        sid: user.sessionID
      }
    });
    result.data = JSON.parse(result.data);
    setUsers(result.data);
  }

  const fetchClients = async () => {
    const result = await axios({
      url: `${process.env.REACT_APP_API}/fetchClients`,
      method: 'post',
      data: {
        user: user.email,
        sid: user.sessionID
      }
    });

    result.data = JSON.parse(result.data);
    setClientsList(result.data);
  }

  useEffect(() => {
    fetchUsers();
    fetchClients();
  }, []);

  return (
    <div className="users">
      <h1>Users</h1>
      <ToastContainer />
        <Button className="addUser" type="submit" onClick={() => { openModal('addUser')}}>
         <FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" /> &nbsp;
         <label className="searchText">Add user</label>
        </Button>
        <div className="scrollbar scrollbar3" id="style-5">
          <div className="force-overflow">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Client</th>
                <th>Access</th>
                <th className="textAlignCenter">Edit</th>
                <th className="textAlignCenter">Delete</th>
              </tr>
            </thead>
            <tbody>
              {
                users && users.length ?
                  users.map((record) => {
                    return (
                      <tr key={record.firstName + record.lastName}>
                        <td>{record.firstName} {record.lastName}</td>
                        <td>{record.clientName}</td>
                        <td>
                          {
                            record.access === "1" && 'Basic user'
                          }
                          {
                            record.access === "2" && 'Admin'
                          }
                          {
                            record.access === "3" && 'Super user'
                          }
                        </td>
                        <td className="textAlignCenter">
                          <Button variant="primary" className="activateBoard fixedDimButton noMarginRight" type="submit" onClick={() => { openModal('editUser', record)}}>
                           <FontAwesomeIcon icon={['fal', 'file-edit']} size="lg" /> &nbsp;
                             Edit
                          </Button>
                        </td>
                        <td className="textAlignCenter">
                          <Button variant="primary" className="disabledBoard fixedDimButton noMarginRight" type="submit" onClick={() => { openModal('deleteUser', record)}}>
                           <FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" /> &nbsp;
                             Delete
                          </Button>
                        </td>
                      </tr>
                    )
                  })

                  :
                  <tr>
                    <td colSpan="6">
                      <div className="spinnerContainer">
                        <Loader
                           type="ThreeDots"
                           color="#02547d"
                           height={70}
                           width={70}
                           timeout={12000} //3 secs
                        />
                      </div>
                    </td>
                  </tr>
              }
            </tbody>
          </Table>
        </div>
      </div>

        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Candidate">
          <button className="closeModal" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="black" size="1x" /></button>
            {
              modalType === 'deleteUser' &&
                <DeleteUser closeModal={closeModal} user={user} selectedUser={selectedUser} selectedUserId={selectedUserId} />
            }
            {
              modalType === 'addUser' &&
                <AddUser closeModal={closeModal} refresh={refresh} user={user} clientsList={clientsList} />
            }
            {
              modalType === 'editUser' &&
                <EditUser closeModal={closeModal} refresh={refresh} record={editUser} user={user} clientsList={clientsList} />
            }
          </Modal>
    </div>
)
}

export default SettingsUsers
