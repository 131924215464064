import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import {
  faTimes,
  faCheck
} from '@fortawesome/free-solid-svg-icons';

const DeleteUser = ({closeModal, selectedUser, selectedUserId, user}) => {
  const confirmDelete = async () => {
    await axios({
      url: `${process.env.REACT_APP_API}/deleteUser`,
      method: 'post',
      data: {
        user: user.email,
        sid: user.sessionID,
        id: selectedUserId
      }
    });

    closeModal(true);
  }


  return(
    <>
      <h1>Delete {selectedUser}</h1>
      <p>Are you sure you want to delete this user?</p>
      <div className="modalFooter">
        <Button variant="success" className="activateBoard" onClick={() => { confirmDelete()} }><FontAwesomeIcon icon={faCheck} color="white" size="1x" />&nbsp; Confirm</Button>
        <Button variant="danger" className="disabledBoard" onClick={closeModal}><FontAwesomeIcon icon={faTimes} color="white" size="1x" />&nbsp; Cancel</Button>
      </div>
    </>
  )
}

export default DeleteUser;
