import { NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import './styles.scss';
import logo from '../../../images/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavLinks({logout, user}) {
  const [menuClass, setMenuClass] = useState('topnav');
//   function myFunction() {
//   var x = document.getElementById("myTopnav");
//   if (x.className === "topnav") {
//     x.className += " responsive";
//   } else {
//     x.className = "topnav";
//   }
// }


// <NavLink exact to="/dashboard" className="link" activeClassName="active"><FontAwesomeIcon icon={['fas', 'search']} size="lg" />&nbsp; Search Candidates</NavLink>
// <NavLink to="/settings" className="link last"><FontAwesomeIcon icon={['fas', 'cog']} size="lg" />&nbsp; Settings</NavLink>
// <div className="loggedIn">
//   <div>
//   <FontAwesomeIcon icon={['fas', 'user']} size="lg" />&nbsp;
//     {
//       user && <>{user.firstName} { user.lastName}</>
//     }
//   </div>
//   <div>
//     <NavLink onClick={() => {logout()}}  to="#" className="logoutLink"><FontAwesomeIcon icon={['fas', 'sign-out-alt']} size="lg" /> Logout</NavLink>
//   </div>
// </div>

    return(
        <div className="navContainer">
          <img alt="Vacancy Poster Candidate Search Portal" src={logo} className="vpLogoMobile"/>
          <div className={menuClass} id="myTopnav">
            <NavLink exact to="/dashboard" className="link active" activeClassName="active"><FontAwesomeIcon icon={['fas', 'search']} size="lg" />&nbsp; Search Candidates</NavLink>
            <NavLink to="/shortlists" className="link link2"><FontAwesomeIcon icon={['fas', 'list']} size="lg" />&nbsp; Shortlists</NavLink>
            <NavLink to="/settings" className="link"><FontAwesomeIcon icon={['fas', 'cog']} size="lg" />&nbsp; Settings</NavLink>
            <NavLink onClick={() => {logout()}} className="link" to="#"><FontAwesomeIcon icon={['fas', 'sign-out-alt']} size="lg" /> Logout</NavLink>
            <span className="icon" onClick={() => {setMenuClass(menuClass === "topnav" ? "topnav responsive" : "topnav")}}>
              <i className="fa fa-bars"></i>
            </span>
          </div>

        </div>
    );
}

export default NavLinks;
